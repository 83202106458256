import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactForm from '../../components/ContactForm';

export default function PageTOC() {

  document.title = `Privacy | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Learn about our data partners and privacy practices.`);


  return (
    <div>
      <h1 className='page-header'>Our Privacy Practices</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>
          <p>AsterismBooks.com uses virtually no third-party code or trackers. Pretty simple, right? Our only analytics are via <a href='https://plausible.io/sites' target='_blank'>Plausible</a>, a company that offers aggregate data with no way of telling who a user is or tracking them over time. We value your privacy more than whatever benefit can be wrung from more intrusive data practices.</p>

          <p>We only collect basic information about our users which is is never resold.</p>
          <p>We use Stripe to manage checkout and billing (though Stripe's code isn't loaded until checkout). You can read more about their privacy and terms of service <a href='https://stripe.com/privacy' target='_blank'>here</a>, or by clicking the links on the checkout page.</p>

          <p>Our email newsletters are sent via MailChimp.</p>

        </div>
        <div className='flex-two'>

        </div>
      </div>
    </div>
  )
}
