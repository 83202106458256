
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { userState } from "../../atoms";
import { useRecoilValue } from "recoil";
import { makeRequest } from "../../Utils";

export default function AdminMenu() {
  const user = useRecoilValue(userState);

  return (
    <div className='pubMenu roomBelowLarge'>
      <div className='pubMenu__header'>
        <div className='pubMenu__image'>
          <div className='pubMenu__name'>{user.email}</div>
        </div>
      </div>
      <div className='pubMenu__inner'>
        <Link className='pubMenuLink' to={'/admin'}>Admin Home</Link>
        <Link className='pubMenuLink' to={'/admin/publishers'}>Publisher List</Link>
        <Link className='pubMenuLink' to={'/admin/products'}>All Products</Link>
        <Link className='pubMenuLink' to={'/admin/lists'}>Lists</Link>
        <Link className='pubMenuLink' to={'/admin/orders'}>Orders</Link>
        <Link className='pubMenuLink' to={'/admin/shipments'}>Shipments</Link>
        <hr />
        <Link className='pubMenuLink' to={'/tools/inventory'}>Inventory</Link>
        <Link className='pubMenuLink' to={'/tools/backorders'}>Backorders</Link>
        <Link className='pubMenuLink' to={'/admin/requests'}>Stock Requests</Link>

        <div className='pseudoLabel'>Tools</div>
        <Link className='pubMenuLink' to={'/tools/barcode-generator'}>Barcode Generator</Link>
        <Link className='pubMenuLink' to={'/tools/shipping'}>Shipping Calculator</Link>
        {user.admin && <Link className='pubMenuLink' to={'/tools/labels'}>Label Maker</Link>}
        {user.admin && <Link className='pubMenuLink' to={'/tools/newsletter-legacy'}>Newsletter Helper</Link>}
      </div>
    </div>
  )
}