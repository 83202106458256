import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SelectControl, NumberControl, DateControl } from '../../components/InputControls';
import { makeRequest, formatISODate, updateObject } from '../../Utils';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { userState } from '../../atoms';
import './style.css';

export default function Publishers() {
  const [pubs, setPubs] = useState([]);

  const wordcount = (text) => {
    return text.split(' ').length;
  }

  const prepDescription = (description) => {
    if (!description) {
      return '';
    } else if (description.length < 350) {
      return description;
    }
    let sentences = description.split('. ');
    let truncated = '';
    for (let i = 0; i < sentences.length; i++) {
      if (truncated.length > 350) {
        break;
      }
      truncated += `${sentences[i]}. `;
    }
    return truncated;
  }

  const getPubs = async () => {
    let data = await makeRequest('publishers', 'GET');
    setPubs(data);
  }

  useEffect(() => {
    getPubs();
  }, [])

  document.title = `Our Publishers | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Learn about the different publishers who make up Asterism Books.`);


  return (
    <div>
      <h1 className='page-header'>Our Publishers</h1>
      <div className='publisherGrid roomAboveLarge'>
        {pubs.map((pub) =>
          <div key={pub.id} className='publisherGridItem'>
            <div className='publisherGridItem__image'>
              {pub.logo && <Link to={`/publisher/${pub.slug}`}><img src={pub.logo['300']} alt={`${pub.name} logo`} /></Link>}
            </div>
            <div className='publisherGridItem__info'>
              <div className='publisherItem__title'>{pub.name}</div>
              <div className='publisherItem__location'>{pub.location}</div>

              <div className='publisherItem__description' dangerouslySetInnerHTML={{ __html: prepDescription(pub.description) }} />
              <Link to={`/publisher/${pub.slug}`}><button className='buttonSecondary'>See More <i className='fa-solid fa-arrow-right'></i></button></Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
