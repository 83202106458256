import React, { useEffect, useState } from 'react';
import { asaEvent, formatISODate, makeRequest, slugify } from '../../Utils';
import './styles.css';
import SidesScroller from '../SideScroller';
import { useRecoilValue } from 'recoil';
import { pageviewState, sessionState } from '../../atoms';

export default function HomepageBookBlock({ source, block, sourceId, title = '', moreText = '', moreLink = '' }) {
  const [list, setList] = useState([]);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);


  const getBestsellers = async () => {
    let data = await makeRequest('bestsellers');
    let slug = data[0].slug;
    let request = await makeRequest(`list?slug=${slug}`);
    setList(request.products);
  }

  const getList = async (id) => {
    let request = await makeRequest(`list?id=${id}&display=true`);
    setList(request.products);
  }

  const getNew = async () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endKey = formatISODate(tomorrow);
    let data = await makeRequest(`all-products?beforeDate=${endKey}&inStockNow=true`, 'GET');
    setList(data.docs);
  }

  const getUpcoming = async () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endKey = formatISODate(tomorrow);
    let data = await makeRequest(`all-products?afterDate=${endKey}&ascending=true`, 'GET');
    setList(data.docs);
  }

  useEffect(() => {
    if (source === 'list') {
      getList(sourceId)
    } else if (source === 'bestsellers') {
      getBestsellers();
    } else if (source === 'new') {
      getNew();
    } else if (source === 'upcoming') {
      getUpcoming();
    }
  }, []);

  return (
    <>
      <div className='newHead flex-layout'>
        <h2>{title}</h2>
        {moreText && moreLink && <a href={moreLink} onClick={() => {
          asaEvent({
            session: session.id,
            category: 'recirc',
            action: 'click',
            label: 'blockListMore',
            locationOne: `${block}_${slugify(title)}`,
            locationTwo: 'sidescroll_list',
            locationThree: 'moreLink',
            testgroup: session.group,
            pagetype: pageview.pagetype,
            pageview: pageview.id,
            campaign: session.campaign,
            value: 1
          });
        }}><span className='moreText'>{moreText}</span><span className='moreMobile'>More <i className='fa-solid fa-arrow-right'></i></span></a>}
      </div>
      <SidesScroller block={block} title={title} list={list} />
    </>
  )
}