import React, { useState, useEffect } from 'react';
import { makeRequest } from '../../Utils';
import HomepageBookBlock from '../../components/HomepageBookBlock';

export default function NewHomepage({ data }) {
  const [homepage, setHomepage] = useState(data ? data : { blocks: [] });

  const getData = async () => {
    let data = await makeRequest('options?id=homepage');
    setHomepage(data);
  }

  useEffect(() => {
    if (!data) {
      getData();
    }
  }, [])

  return (
    <>
      {homepage.blocks.map((block, index) => {
        if (block.type === 'products') {
          return (<HomepageBookBlock block={index} title={block.title} source={block.source} sourceId={block.sourceId} moreText={block.moreText} moreLink={block.moreLink} />)
        } else if (block.type === 'welcome') {
          return (<WelcomeBlock />)
        } else {
          return (<></>)
        }
      })}
    </>
  )
}

function WelcomeBlock() {
  return (
    <div className='homepageWelcome'>
      <div className='homepageWelcome__inner'>
        <div className='homepageTitle'>Welcome to <span className='homepageTitlePress'>Asterism</span>!</div>
        <div className='homepageDescription'>
          <p>Asterism Books is a trade distributor and online bookstore designed, built, and run by independent publishers. We aim to bring the best of small-press publishing together in one place, highlighting the quality and diversity of the independent publishing landscape.</p>
        </div>
      </div>
    </div>
  )
}