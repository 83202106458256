import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { TextControl } from '../../components/InputControls';
import { checkAuth, makeRequest, wishlistChange } from '../../Utils';
import { userState, messageState } from '../../atoms';

export default function CreateAccount({ context }) {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [userData, setUserData] = useRecoilState(userState);
  const [message, setMessage] = useRecoilState(messageState);
  const [forgot, setForgot] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  document.title = `Create an Account | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', 'Create an account to manage your orders, wishlist, and more.');

  const navigate = useNavigate();

  const submit = async () => {
    let data = await makeRequest('create-account', 'POST', {
      email: email,
      pass: pass
    });
    if (data.error) {
      setMessage({ type: 'error', temp: true, text: data.message, label: 'Error' })
    } else {
      setMessage({ type: 'success', label: 'success', text: 'Account created!', temp: true });
      localStorage.setItem('asterismToken', data.token);
      let authData = await checkAuth(data.token);
      setUserData(authData);
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('wishlist')) {
        let add = await wishlistChange(authData.id, searchParams.get('wishlist'), true);
        navigate('/account/wishlist');
      } else {
        navigate('/account');
      }
    }
  }

  if (userData.id) {
    return (
      <div>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/account'}>your account</Link></h4>
      </div>
    )
  }

  return (
    <div className='card-container'>
      <div className='roomBelowMedium'>
        <h1 className='page-header'>Create an Account</h1>
      </div>

      <div className='loginForm'>
        <TextControl
          value={email}
          change={(value) => { setEmail(value) }}
          label={`Account Email`}
          disabled={loggingIn}
          valid={false}
          type='text'
          autocomplete='username'
          required={true}
          bypass={true}
          description={''}
        />

        <div className='inputControl'>
          <div className='inputControl__label'>Password</div>
          <input disabled={loggingIn} type='password' value={pass} onChange={(e) => { setPass(e.target.value) }} />
        </div>

        <div className='inputControl'>
          <div className='inputControl__label'>Confirm Password</div>
          <input disabled={loggingIn} type='password' value={confirmPass} onChange={(e) => { setConfirmPass(e.target.value) }} />
        </div>
      </div>

      <div className='buttonPair'>
        <button className='buttonPrimary' disabled={loggingIn || pass.length < 6 || pass !== confirmPass || email.length == 0} onClick={submit}>Create Account</button>
        {/*<button className='buttonSecondary' onClick={() => { setForgot(true) }}>Forgot Password</button>*/}
      </div>
    </div>
  )
}
