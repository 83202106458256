import React, { useState, useRef, useEffect } from 'react';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import { Loading } from '../../Utils';

export default function Loader({ fetching, getMore, hasMore, page }) {
  const [ref, { entry }] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;

  useEffect(() => {
    if (!fetching && isVisible && hasMore) {
      getMore(true);
    }
  }, [isVisible]);

  if (!hasMore) {
    return (<></>)
  }

  return (
    <div ref={ref}></div>
  )
}