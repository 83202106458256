import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeRequest, Loading } from '../../Utils';

export default function Product() {

  const navigate = useNavigate();

  const findProduct = async (id) => {
    let query = id.replace(/–|-/g, '');
    let data = await makeRequest(`product?id=${query}`);
    if (data.slug) {
      navigate(`/product/${data.slug}`)
    } else {
      navigate('/404')
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if (!id) {
      navigate('/404')
    } else {
      findProduct(id);
    }
  }, [])


  return (
    <div>
      <div className='loadingMessage'><Loading /> <span className='loadingMessage__text'>You will be redirected in one moment...</span></div>
    </div>
  )
}
