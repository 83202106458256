import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { TextControl } from '../InputControls';
import { checkAuth, makeRequest } from '../../Utils';
import { userState, messageState } from '../../atoms';

export default function LoginForm({ mode, cancel }) {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [userData, setUserData] = useRecoilState(userState);
  const [message, setMessage] = useRecoilState(messageState);

  //document.title = 'Chatwin Printing | Login';
  //document.querySelector('meta[name="description"]').setAttribute('content', 'Login to your account to access your projects.');

  const navigate = useNavigate();

  const submit = async () => {
    let user = {
      email: email,
      pass: pass,
      mode: mode
    }
    let data = await makeRequest('login', 'POST', user);
    if (data.token) {
      setMessage({ type: 'success', label: 'success', text: 'User logged in successfully!', temp: true });
      localStorage.setItem('asterismToken', data.token);
      let authData = await checkAuth(data.token);
      setUserData(authData);
    } else {
      setMessage({ type: 'error', temp: true, text: data.message, label: 'Error' })
    }
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <h4>{mode == 'publisher' ? 'Log in to your publisher account' : 'Log in to your account'}</h4>
      </div>
      <TextControl
        value={email}
        change={(value) => { setEmail(value) }}
        label={`Account Email`}
        valid={false}
        type='text'
        required={true}
        bypass={true}
        description={''}
        name='Email'
      />
      <TextControl
        value={pass}
        change={(value) => { setPass(value) }}
        label={`Password`}
        valid={false}
        type='password'
        bypass={true}
        required={true}
        description={''}
        name='Password'
      />

      <div className='cartButtons'>
        <button className='buttonPrimary' onClick={submit}>Login</button>
        <button className='buttonSecondary' onClick={cancel}>Cancel</button>
      </div>
    </div>
  )
}
