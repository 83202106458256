import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactForm from '../../components/ContactForm';

export default function PageAbout() {

  document.title = `About Us | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Learn about Asterism Books, our policies, how to join, and how to contact us!`);


  return (
    <div>
      <h1 className='page-header'>About Asterism</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>
          <p>Asterism Books is an online bookstore and trade distributor for books by independent presses. We aim to work with both booksellers and publishers to forge a more sustainable path for small-press publishing, emphasizing transparency, communication, and collaboration.</p>

          <p>Asterism was founded in 2021 by Joshua Rothes of Sublunary Editions, as a small co-operative of presses offering direct-to-trade distribution for member presses. Outgrowing our initial model, Asterism Books re-launched in February of 2023 as a partnership between Joshua Rothes and Phil Bevis, co-publisher of Chatwin Books and the proprietor of Arundel Books, with a warehouse, fulfillment center, and headquarters in the Pioneer Square neighborhood of Seattle.</p>

          <p>We’ve worked hard to build state-of-the-art software for publishers, as well as for our own operations, including inventory management, shipping, and reporting dashboards. </p>

          <h3>How we’re different</h3>
          <p>In addition to be founded by and run by small presses, Asterism started by rethinking as many of the assumptions behind how small presses do distribution as possible. We do not offer returns on wholesale orders (with exceptions for event orders and damaged copies), and, in exchange for booksellers, we promise never to sell into online discounters that can undercut them on price. Our bread-and-butter is selling books to readers, booksellers, and librarians, rather than adding additional middle-men to the process.</p>
          <p> We offer near-immediate payouts to our publishers, and never charge exploitative fees.</p>

          <h3>How to work with us</h3>
          <p>If you’re a publisher, <Link to='/for-publishers'>visit this page</Link> to learn more about our terms and use the application to let us know you’d like to work together.</p>
          <p>Booksellers looking to apply for an account can read up on our terms and <Link to='/for-retailers'>fill out an application here</Link>, and librarians can do the same <Link to='/for-libraries'>here.</Link></p>

          <h3>How to get in touch with us</h3>

          <p>For general questions, use the form on this page, or email info@asterismbooks.com</p>
          <p>For order support, you can also use the form on this page, or email support@asterismbooks.com</p>
          <p>To get in touch about questions for publishers or booksellers not covered on the website, or to discuss business partnerships with Asterism, email Joshua Rothes at josh@asterismbooks.com</p>
          <p>To request to be added to our mailing list for print catalogs, query about marketing opportunities, or let us know about something cool one of our presses has done, email Laura Paul at laura@asterismbooks.com</p>

          <h3>Mailing Address</h3>
          <p><strong>For mail, UPS, or FedEx:</strong></p>
          <div>Asterism Books</div>
          <div>568 1st Avenue South, Ste 120</div>
          <div>Seattle, WA 98104</div>
          <p><strong>For freight deliveries:</strong></p>
          <div>Asterism Book</div>
          <div>569 Occidental Avenue South</div>
          <div>Seattle, WA 98134</div>
        </div>
        <div className='flex-two'>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}
