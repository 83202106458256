import React, { useEffect, useState } from 'react';
import { makeRequest } from '../../Utils';

export default function StoreMap() {

  const getStores = async () => {
    let map = window.L.map('map').setView([42.0902, -45.7129], 4);
    window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);
    let stores = await makeRequest('store-map');
    stores.forEach((store) => {
      let marker = window.L.marker([parseFloat(store.latitude), parseFloat(store.longitude)]).addTo(map);
      marker.bindPopup(store.website ? `<a href="${store?.website.includes('https://') ? store.website : `https://${store.website}`}" target="_blank">${store.name}</a>` : store.name);
    })
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';
    script.integrity = 'sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=';
    script.crossOrigin = '';
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.crossOrigin = '';
    style.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css'
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(style);
    body.appendChild(script);
    setTimeout(() => {
      getStores();
    }, 400);
  }, [])

  return (
    <div>
      <h1 className='page-header'>Asterism Stockists</h1>
      <div id='map' style={{ height: '800px' }}></div>
    </div>
  )
}