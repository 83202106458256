import React, { useState, useEffect } from 'react';

export default function PageAbout() {

  document.title = `404 Not Found | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', ``);


  return (
    <div>
      <h1 className='page-header'>404 Not found</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>
          You might be looking for something that doesn't exist. It happens.
        </div>
        <div className='flex-two'>

        </div>
      </div>
    </div>
  )
}
