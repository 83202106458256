import React, { useState } from 'react';
import { makeRequest } from '../../Utils';
import './style.css';

export default function EmailSignup({ featured = false }) {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    setSubmitted(true);
    await makeRequest(`newsletter?email=${email}`);
  }

  return (
    <div className={`newsletterSignup ${featured && 'featured'}`}>
      <h3>Get updates!</h3>
      <p>Sign up to receive weekly emails highlighting new titles, curated picks, and more!</p>
      {submitted ?
        <div className='newsletterFinal' onClick={() => { setSubmitted(false) }}>Thanks for signing up!</div>
        :
        <div className='newsletterContainer'>
          <input value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Enter your email address' disabled={submitted} />
          <button className='buttonPrimary buttonPrimary-small' onClick={submit}>Submit</button>
        </div>
      }
    </div>
  )
}
