import React, { useState } from 'react';
import { ticketTypes } from '../../Variables';
import { TextAreaControl, TextControl, SelectControl } from '../../components/InputControls';
import './style.css';
import { LoadingMessage, makeRequest } from '../../Utils';

export default function SupportPage() {
  return (
    <div>
      <h1 className='page-header'>Customer Support Center</h1>
      <div className='supportPage'>
        <div>
          <h3>Check your order status</h3>
          <StatusCheck />
          <hr />
          <h3>FAQs</h3>
          <p>Below are some common questions we get from customers. Please read through the FAQs before contacting us.</p>
          <p><strong>When is my package going to ship?</strong></p>
          <p>For items we have in-stock, an order will usually ship no later than the next business day. For pre-ordered items, i.e., products with a publication date in the future, we will typically ship about a week before publication date, or as soon as we receive copies from the publisher.</p>
          <p><strong>How can I make sure I entered the correct address?</strong></p>
          <p>Use the form above, or check the receipt that was emailed to you after placing your order.</p>
          <strong>My tracking link shows that the package has been delivered, but I don't see it.</strong>
          <p>Here are the steps to check on your package:</p>
          <ul>
            <li>You can use the above order status form or your e-mail to verify that the shipping address is correct.</li>
            <li>Contact your local post office or carrier store</li>
            <li>For USPS, file a Missing Mail report online at <a href='https://usps.my.site.com/emailus/s/?_gl=1*q8ywoe*_gcl_au*MTQzMjMxNjc2LjE3MTM0MTU3Njc.*_ga*MjAwODQ5ODg4NC4xNzA1NTM5OTg0*_ga_3NXP3C8S9V*MTcxOTI0NjUyNi41NC4xLjE3MTkyNDY1NTMuMC4wLjA.' target='_blank'>this link</a></li>
            <li>If the package is still missing, contact us with the results of the Missing Mail Report and we will file an insurance claim and issue a refund or, where possible, a replacement</li>
            <li><strong>NOTE: We will not issue a refund/replacement if the address entered on the order was incorrect.</strong></li>
          </ul>
          <p><strong>My international package says it has shipped, but there has been no update on the tracking for several days.</strong></p>
          <p>That's usually nothing to worry about! Our affordable shipping through Asendia means that each individual package is shipped as part of a much larger parcel to the final destination. This typically means that tracking will be slow to update while the package is in the US, for as long as two weeks, and then the tracking will update with the local carrier tracking number once it has cleared customers in your country. If it has been more than three weeks, please contact us!</p>
          <p><strong></strong></p>
          <p></p>
          <p><strong></strong></p>
          <p></p>
          <p><strong></strong></p>
          <p></p>
        </div>
        <div>
          <h3>Contact Us</h3>
          <p>Email us at support [@] asterismbooks.com or use the form below! We'll be in touch within one business day.</p>
          <SupportForm />
        </div>
      </div>
    </div>
  )
}

function StatusCheck() {
  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [checking, setChecking] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [data, setData] = useState({});

  const checkStatus = async () => {
    setError('');
    setChecking(true);
    if (!id) {
      setError('Please input your order ID');
      setChecking(false);
      return;
    } else if (!email) {
      setError('Please enter the email associated with your order');
      setChecking(false);
      return;
    }
    try {
      let status = await makeRequest(`order-status?id=${id}&email=${email}`);
      if (status.message) {
        setStatusMessage(status.message);
        setData(status.order);
      } else {
        setError(`We couldn't automatically check your order status. Please get in touch for additional help!`)
      }
      setChecking(false);
    } catch (e) {
      setError(`We couldn't automatically check your order status. Please get in touch for additional help!`)
    }
  }

  return (
    <>
      <TextControl value={id} label='Your Order Number' help='AST-01-XXXXXX' change={setId} />
      <TextControl value={email} label='Your Email' help='Must match the email on the receipt' change={setEmail} />
      {error && <div className='errorMessage'>{error}</div>}
      {checking ? <LoadingMessage text='Checking your order status...' /> : <button className='buttonPrimary' onClick={checkStatus}>Check</button>}
      {statusMessage &&
        <div className='orderStatusMessage'>
          <span dangerouslySetInnerHTML={{ __html: statusMessage }} />
          {data.customer?.address && <div>
            <div className='pseudoLabel roomAboveMedium'>The shipping address on this order is:</div>
            <div><strong>{data.customer.address.name}</strong></div>
            <div>{data.customer.address.lineOne}</div>
            <div>{data.customer.address.lineTwo}</div>
            <div>{data.customer.address.city} {data.customer.address.state} {data.customer.address.postalCode}</div>
            <div>{data.customer.address.country}</div>
          </div>}
        </div>}
    </>
  )
}

function SupportForm() {
  const [order, setOrder] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('gi');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const createTicket = async () => {
    let save = await makeRequest('support-form', 'POST', {
      order: order,
      email: email,
      name: name,
      type: type,
      message: message
    });
    if (save.ok) {
      console.log('Hi')
      setSubmitted(true);
      setSubmitting(false);
    } else {
      setSubmitting(false);
      setError(true);
    }
  }

  if (submitted) {
    return (<div>Thanks for your message! We'll be in touch shortly.</div>)
  }

  return (
    <>
      <TextControl
        value={email}
        change={setEmail}
        label='Your Email'
      />
      <TextControl
        value={name}
        change={setName}
        label='Your Name (optional)'
      />
      <SelectControl
        value={type}
        change={setType}
        label={'How can we help you?'}
        canBeEmpty={true}
        options={Object.keys(ticketTypes).map((type) => {
          return {
            value: type,
            label: ticketTypes[type]
          }
        })}
      />
      <TextControl
        value={order}
        change={setOrder}
        label='Your order or receipt number'
      />
      <TextAreaControl
        value={message}
        change={setMessage}
        label='Tell us about your issue'
      />
      {error && <div className='errorMessage'>There was a problem submitting your request.</div>}
      {submitting ? <LoadingMessage text='Creating support ticket' /> : <button className='buttonPrimary' onClick={createTicket}>Open Support Ticket</button>}

    </>
  )
}