import React, { useEffect, useState } from 'react';
import { ReactComponent as Close } from '../../icons/close.svg';
import { useRecoilState, useResetRecoilState, useRecoilValue } from 'recoil';
import { messageState, cartMessageState, cartOpenState, sessionState, pageviewState } from '../../atoms';
import { analyticsEvent, asaEvent } from '../../Utils';
import './style.css';

export default function Message() {
  const [message, setMessage] = useRecoilState(messageState);
  const clearMessage = useResetRecoilState(messageState);
  const [cartOpen, setCartOpen] = useRecoilState(cartOpenState);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);

  useEffect(() => {
    console.log(message);
    if (message.temp) {
      setTimeout(() => {
        clearMessage()
      }, 3500)
    }
    if (message.cart) {
      asaEvent({
        session: session.id,
        category: 'cart',
        action: 'cartMessage',
        label: 'display',
        locationOne: '',
        locationTwo: '',
        locationThree: '',
        testgroup: session.group,
        pagetype: pageview.pagetype,
        pageview: pageview.id,
        campaign: session.campaign,
      });
    }
  }, [message]);

  if (!message.text) {
    return (<></>)
  }

  return (
    <div className={`displayMessage ${message.type} ${message.classList}`}>
      {message.img && <div className='messageImage'><img src={message.img} alt='Book Cover Thumbnail' /></div>}
      <div className='messageInfo'>
        <div className='messageLabel'>{message.label ? message.label : message.type}</div>
        <div className='messageText' dangerouslySetInnerHTML={{ __html: message.text }}></div>
      </div>
      {!message.temp && !message.cart && <a className='messageClear' onClick={clearMessage}><Close /></a>}
      {message.cart &&
        <div className='messageButtons'>
          <button className='buttonPrimary-small' onClick={() => {
            setCartOpen(true);
            clearMessage();
            analyticsEvent('cartClick', { location: 'message' });
            asaEvent({
              session: session.id,
              category: 'message',
              action: 'cartOpen',
              label: 'viewCart',
              locationOne: '',
              locationTwo: '',
              locationThree: '',
              testgroup: session.group,
              pagetype: pageview.pagetype,
              pageview: pageview.id,
              campaign: session.campaign,
            });
          }}>View Cart</button>
          <button className='buttonSecondary-small' onClick={() => {
            clearMessage();
            asaEvent({
              session: session.id,
              category: 'message',
              action: 'close',
              label: 'keepShopping',
              locationOne: '',
              locationTwo: '',
              locationThree: '',
              testgroup: session.group,
              pagetype: pageview.pagetype,
              pageview: pageview.id,
              campaign: session.campaign,
            });
          }}>Keep Shopping</button>
        </div>
      }
    </div>
  )
}