import React from 'react';
import './style.css';
import OldHomepage from './old';
import NewHomepage from './new';
import { useRecoilValue } from 'recoil';
import { sessionState } from '../../atoms';


export default function Homepage() {
  document.title = 'Asterism Books | Online Bookstore and Wholesale for Independent Publishers';
  const session = useRecoilValue(sessionState);

  if (!session.group) {
    return (<></>)
  }

  return (
    <div>
      {session.group === 'A' ? <NewHomepage /> : <OldHomepage />}
    </div >
  )
}
