import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApplyFormRetail from '../../components/ApplyFormRetail';

export default function PageAbout() {

  document.title = `Asterism for Bookstore | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `If you're a bookstore, Asterism would love to work with you! Read about our terms and conditions and apply for an account.`);


  return (
    <div>
      <h1 className='page-header'>For Retailers</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>
          Hello, booksellers! We would love to have you join Asterism. You may be curious about how we work, so you can read the FAQ below. If you're ready to apply, fill out the form and you're on your way!
          <h2>FAQs</h2>
          <p><strong>Q: Can I join?</strong></p>
          <p>A: If you are a brick and mortar retailer, yes. (Happy to work with pop-up shops, book mobiles, etc. as well!) We do not, however, accept orders from other distributors/brokers or online retailers at this time. If you fall into one of these categories and would like us to consider working with you, get in touch at info@asterismbooks.com</p>

          <p><strong>Q: Are you just another distributor for small press titles?</strong></p>
          <p>A: We don't think so! We are founded and run by small presses. Asterism was started by Joshua Rothes from Sublunary Editions, and includes the publishers of Chatwin Books, Kernpunkt, 11:11 Press, and Sagging Meniscus on our advisory board. When an order is placed through Asterism, our presses are paid out immediately, minus a fee to that platform which goes toward shared expenses like web hosting and marketing.</p>

          <p><strong>Q: What are your terms like? Returns? Minimum orders?</strong></p>
          <p>A: Each publisher sets their own discounts, but we generally encourage our presses to offer at least 40% on trade titles. If you want to prepay (and we'd love for you to!), you get an extra 8% off on your order. Invoices are due within 30 days. We do not accept returns, and do not require any minimum order.</p>
          <p><strong>Q: Are there exceptions to the return policy?</strong></p>
          <p>A: Yes! We will of course replace/offer credit for damaged books. <strong>We also allow returns on orders for events within 30 days of the event.</strong> We offer 90 days returns on books ordered by college bookstores for course materials.</p>
          <p><strong>Q: How about shipping? Are we paying for multiple packages?</strong></p>
          <p>A: To keep things fair, we charge a shipping rate equivalent to a single package for the entire order, regardless of how many individual packages there are. This keeps things fair for everyone, we think. Asterism does ship some orders centrally from our Seattle offices, but we do allow our presses the flexibility to fulfill their own orders.</p>
          <p><strong>Q: How do I sign up for an account?</strong></p>
          <p>Fill out the application on this page and we'll get back to you in the next 24 hours!</p>
        </div>
        <div className='flex-two'>
          <ApplyFormRetail />
        </div>
      </div>
    </div>
  )
}
