import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeRequest, Loading, returnISODate } from '../../Utils';
import { userState, messageState } from '../../atoms';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { DateControl, SelectControl } from '../../components/InputControls';
import BarStyleGraph from '../../components/BarStyleGraph';
import moment from 'moment';
import './style.css';


export default function PubHome({ context = 'publisher' }) {
  const [orders, setOrders] = useState([]);
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const setMessage = useSetRecoilState(messageState);
  const [report, setReport] = useState({ counts: {}, products: {}, payouts: {}, books: {}, gross: {} });
  const [start, setStart] = useState(moment().startOf('month').toISOString());
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');
  const [end, setEnd] = useState(moment().toISOString());
  const [productTitle, setProductTitle] = useState('');
  const [bestsellers, setBestsellers] = useState([]);
  const [list, setList] = useState([])

  document.title = `Publisher Home | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', ``);

  const getProducts = async () => {
    let data = await makeRequest(`private-products?per=${100}&page=${0}`, 'GET');
    if (data.products) {
      let prods = data.products;
      prods.sort((a, b) => {
        let textA = a.title.toUpperCase();
        let textB = b.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      setProducts(prods);
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    let newList = report.products;
    let arr = [];
    Object.keys(newList).forEach((id) => {
      let value = newList[id];
      value.id = id;
      arr.push(value);
    })
    setList(arr.sort((a, b) => b.count - a.count));
    setBestsellers(arr.sort((a, b) => b.count - a.count).slice(0, 15));
  }, [products, report])



  const buildReport = (orderList) => {
    let counts = {
      retail: 0,
      wholesale: 0,
      library: 0,
      publisher: 0,
    }
    let totals = {
      retail: 0,
      wholesale: 0,
      library: 0,
      publisher: 0,
    }
    let bookTotals = {
      retail: 0,
      wholesale: 0,
      library: 0,
      publisher: 0
    }
    let gross = {
      retail: 0,
      wholesale: 0,
      library: 0,
      publisher: 0
    }
    let products = {};
    orderList.forEach((order) => {
      if (order.type !== 'dropship') {
        counts[order.type]++;
        if (!product) {
          totals[order.type] += order.payments[user.id];
        }
        Object.keys(order.items).forEach((id) => {
          if (order.items[id].publisher === user.id && order.type !== 'dropship') {
            products[id] = products[id] ? products[id] : { count: 0, gross: 0, net: 0 }
            products[id].title = order.items[id].title;
            products[id].count += order.items[id].quantity;
            products[id].gross += order.items[id].quantity * order.items[id].salePrice;
            products[id].net += order.items[id].quantity * order.items[id].salePrice * .76;

            if (product && id === product) {
              gross[order.type] += order.items[id].quantity * order.items[id].salePrice;
              bookTotals[order.type] += order.items[id].quantity;
            } else if (!product) {
              bookTotals[order.type] += order.items[id].quantity;
              gross[order.type] += order.items[id].quantity * order.items[id].salePrice;
            }
          }
        })
      }
    });
    setReport({
      counts: counts,
      payouts: totals,
      products: products,
      books: bookTotals,
      gross: gross,
    })
  }

  const getOrders = async () => {
    setOrders([]);
    setLoading(true);
    try {
      let data = await makeRequest(`publisher-report?start=${start}&end=${end}&product=${product}`, 'GET');
      if (data.error) {
        throw new Error(data.message);
      }
      setOrders(data);
      buildReport(data);
    } catch (e) {
      let message = e ? e : 'Something went wrong';
      setMessage({ type: 'error', label: 'Error', text: message, temp: true });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (user.id) {
      getOrders();
    }
  }, [user.id, start, end, product])

  const returnProductName = (id) => {
    let name = '';
    if (!id) {
      return name;
    }
    for (let i = 0; i < products.length; i++) {
      if (products[i]._id == product) {
        name = products[i].title;
        break;
      }
    }
    return name;
  }

  useEffect(() => {
    if (product) {
      let name = returnProductName(product);
      setProductTitle(name);
    } else {
      setProductTitle('')
    }
  }, [product, products]);

  const getPubTotal = (order) => {
    let total = 0;
    let pubTotal = 0;
    Object.values(order.items).forEach((item) => {
      if (item.publisher === user.id) {
        pubTotal += item.quantity * item.salePrice;
      }
      total += item.quantity * item.salePrice;
    });
    let mismatch = Math.round(total) > Math.round(order.subtotal);
    let prePaid = order.type === 'wholesale' && order.session && !order.invoice;
    if (mismatch && prePaid) {
      pubTotal = pubTotal * .92;
    }
    return pubTotal;
  }

  const getPubPayment = (order) => {
    let pub = order.payments[user.id];
    if (order.shippingSplit && order.shippingSplit[user.id]) {
      pub = pub - order.shippingSplit[user.id];
    }
    return pub;
  }

  const getAsterismFee = (order) => {
    let pub = order.payments[user.id];
    if (order.shippingSplit && order.shippingSplit[user.id]) {
      pub = pub - order.shippingSplit[user.id];
    }
    let total = 0;
    let pubTotal = 0;
    Object.values(order.items).forEach((item) => {
      if (item.publisher === user.id) {
        pubTotal += item.quantity * item.salePrice;
      }
      total += item.quantity * item.salePrice;
    });
    let mismatch = Math.round(total) > Math.round(order.subtotal);
    let prePaid = order.type === 'wholesale' && order.session && !order.invoice;
    if (mismatch && prePaid) {
      pubTotal = pubTotal * .92;
    }
    return pubTotal - pub;
  }

  const exportSelected = () => {
    let titles = {};
    let csv = '';
    csv += 'Order ID (required),'
    csv += 'Order Date,'
    csv += 'Order Type,'
    csv += 'Order Status,'
    csv += 'Customer Email,'
    csv += 'Order Country,'
    csv += 'Publisher Total,'
    csv += 'Publisher Less Fee,'
    csv += 'Asterism Fee,'
    csv += 'Publisher Shipping,'
    csv += 'Total Publisher Payout'
    csv += 'Items\n'
    orders.forEach((order, index) => {
      csv += `"${order._id}",`
      csv += `"${moment(order.created).format('L')}",`
      csv += `${order.type},`;
      csv += `${order.status},`;
      csv += `${order.customer.email.replace(',', ' ')},`;
      csv += `${order.customer?.address?.country ? order.customer.address.country.replace(',', ' ') : 'US'},`;
      csv += `${getPubTotal(order).toFixed(2)},`;
      csv += `${getPubPayment(order).toFixed(2)},`;
      csv += `${getAsterismFee(order).toFixed(2)},`;
      csv += `${order?.shippingSplit[user.id] ? order.shippingSplit[user.id].toFixed(2) : 0},`;
      csv += `${order.payments[user.id].toFixed(2)}`;
      csv += `${Object.keys(order.items).filter((key, i) => {
        if (key === product || (!product && order.items[key].publisher === user.id)) {
          return `${order.items[key].title} x${order.items[key].quantity}`
        }
      }).join('; ').replace(/\,/g, ' ').replace('#', 'no.')},`;
      csv += '\n';
      Object.keys(order.items).forEach((key) => {
        if (titles[key] && order.items[key].publisher == user.id) {
          titles[key].quantity += order.items[key].quantity;
        } else if (order.items[key].publisher == user.id) {
          titles[key] = {
            title: order.items[key].title,
            quantity: order.items[key].quantity
          }
        }
      })
    });
    csv += '\n';
    csv += '\n';
    csv += '\n';
    csv += 'ISBN, Title, Total\n';
    Object.keys(titles).forEach((id) => {
      csv += `${id}, ${titles[id].title.replaceAll(',', '')}, ${titles[id].quantity}\n`
    })

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `asterism-orders-export-${returnISODate(start)}-${returnISODate(end)}${product ? `-${product}` : ''}.csv`;
    hiddenElement.click();
  }

  return (
    <div className='admin-page'>
      <div className='flex-layout pageHeadContainer roomBelowLarge'>
        <div className=''>
          <h1 className='page-header flex-centered'>{user.name} Overview</h1>
          <div className='dateRange'>{moment(start).format('LL')} to {moment(end).format('LL')} {product && <span> for <em>{productTitle}</em></span>}</div>
        </div>
        <div className='flex-layout filters flexColMobile'>
          <DateControl
            value={start}
            change={setStart}
            label={'Start Date'}
            valid={false}
            type='text'
            disabled={loading}
          />
          <DateControl
            value={end}
            change={setEnd}
            label={'End Date'}
            valid={false}
            type='text'
            disabled={loading}
          />
          <SelectControl
            value={product}
            change={setProduct}
            disabled={loading}
            label={'Title'}
            help={''}
            options={[{ value: '', label: 'All titles' }].concat(products.map((product) => {
              return {
                value: product._id,
                label: `${product.title} ${product.authors.length ? `(${product.authors[0]})` : ''}`
              }
            }))}
          />
        </div>
      </div>

      <div className='flex-layout flexColMobile'>
        <div className='flex-four'>
          {loading && <div className='loadingMessage'><Loading /> <span className='loadingMessage__text'>Building report...</span></div>}

          {!loading &&
            <div>
              <div className='flex-layout flexColMobile'>
                <div className='flex-one bigStat'>
                  <div className='breakdown'>
                    <BarStyleGraph label='Total Orders' style='number' data={report.counts} />
                  </div>
                </div>
                <div className='flex-one bigStat'>
                  <div className='breakdown'>
                    <BarStyleGraph label='Total Books Sold' style='number' data={report.books} />
                  </div>
                </div>
              </div>
              <div className='flex-layout'>
                <div className='flex-one bigStat'>
                  <div className='breakdown'>
                    <BarStyleGraph label='Gross Sales' total={Object.values(report.gross).reduce((partialSum, a) => partialSum + a, 0)} style='currency' data={report.gross} />
                  </div>
                </div>
                <div className='flex-one bigStat'>
                  {!product && <div>
                    <div className='breakdown'>
                      <BarStyleGraph label='Est. Payouts' total={Object.values(report.payouts).reduce((partialSum, a) => partialSum + a, 0)} style='currency' data={report.payouts} />
                    </div>
                  </div>}
                  {product &&
                    <div className='fyi'><em>Because of the complexity of inventory, shipping, discounts, etc., estimated payouts are not available on a per-title basis.</em></div>
                  }
                </div>
              </div>
            </div>
          }

        </div>
        {!product && <div className='flex-two'>
          <div className='pseudoLabel roomBelowLarge'>Bestsellers for This Period</div>
          {loading && <div className='loadingMessage'><Loading /> <span className='loadingMessage__text'>Building report...</span></div>}

          {!loading && orders.length == 0 && <div><em>No sales in this period.</em></div>}
          {!loading && bestsellers.map((title, index) =>
            <div className='bestSeller' key={title.id}><span className='bestSellerNo'>{index + 1}.</span><Link to={`/pub/product/${title.id}`}>{title.title}</Link> - {title.count} sold</div>
          )}
        </div>}

      </div>
      <div>
        <h3>All Products</h3>
        <div className='asterismGridItem asterismGridHeader' style={{ gridTemplateColumns: `1fr 2fr 1fr 1fr 1fr` }}>
          <div className='asterismGridColumn'>ID</div>
          <div className='asterismGridColumn'>Title</div>
          <div className='asterismGridColumn'>No. Sold</div>
          <div className='asterismGridColumn'>Gross Sales</div>
          <div className='asterismGridColumn'>Net Sales</div>
        </div>
        {list.map((product) =>
          <div key={product.id} className='asterismGridItem' style={{ gridTemplateColumns: `1fr 2fr 1fr 1fr 1fr` }}>
            <div className='asterismGridColumn'>{product.id}</div>
            <div className='asterismGridColumn'>{product.title}</div>
            <div className='asterismGridColumn'>{product.count}</div>
            <div className='asterismGridColumn'>${product.gross.toFixed(2)}</div>
            <div className='asterismGridColumn'>${product.net.toFixed(2)}</div>
          </div>
        )}
      </div>
      <div className='flex-layout'>
        <h3>All Orders</h3>

        <div>
          <button className='buttonSecondary' onClick={exportSelected}>Export Order Data</button>
        </div>
      </div>
      <div className='flex-layout subHeader orderItem mobHide'>
        <div className='flex-one'>Type</div>
        <div className='flex-one'>Order Date</div>
        <div className='flex-one'>Status</div>
        <div className='flex-one'>Customer</div>
        <div className='flex-one'>Order Total</div>
        <div className='flex-two'>items</div>
      </div>
      <div className='order-table'>
        {loading && <div className='loadingMessage'><Loading /> <span className='loadingMessage__text'>Loading your orders...</span></div>}
        {orders && orders.map((order) =>
          <div className={`customerOrder orderItem flex-layout flexColMobile ${order.status === 'Pending' && 'dim'}`} key={order._id}>
            <div className='flex-one'><div className={`orderType`}>{order.type}</div></div>
            <div className='flex-one'><span><Link to={`/pub/order/${order._id}`}>{moment(order.created).format('LLL')}</Link></span></div>
            <div className='flex-one'><div className={`orderStatus ${order.status.toLowerCase()}`}>{order.status}</div></div>
            <div className='flex-one'><span>{order.customer.address.name}</span></div>
            <div className='flex-one'><span>${order.payments[user.id].toFixed(2)}</span></div>
            <div className='flex-two'>
              <div className='orderItems'>
                {Object.values(order.items).filter((item) => { return item.publisher === user.id }).map((item, index) =>
                  <div className='orderListItem' key={item.title}>
                    {item.title} <span className='orderListItem__quantity'>x{item.quantity}</span> <span className='orderListItem__price'>@{item.salePrice.toFixed(2)}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  )
}
