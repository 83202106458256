import React, { useEffect, useState } from 'react';
import { makeRequest } from '../../Utils';
import { useParams, Link } from 'react-router-dom';
import ProductListItem from '../../components/ProductListItem';
import showdown from 'showdown';

const converter = new showdown.Converter();

export default function List() {
  const [list, setList] = useState({});

  const { slug } = useParams();

  const getList = async () => {
    let data = await makeRequest(`list?slug=${slug}`);
    document.title = `${data.title} | Asterism Books`;
    document.querySelector('meta[name="description"]').setAttribute('content', `${data.description}`);

    setList(data);
  }

  useEffect(() => {
    getList();
  }, [slug]);

  return (
    <div>
      <div>
        <div>
          <div className='publisherHeader'>
            {list.image &&
              <div className='publisherLogo'>
                <img className='publisherLogo__image' src={list.image['600']} alt={`${list.title} cover image`} />
              </div>
            }
            <div>
              <div className='publisherLocation'>A list by {list.publisherData && list.publisher !== 'asterism' ? <Link to={`/publisher/${list.publisherData.slug}`}>{list.publisherData.name}</Link> : 'Asterism Books'}</div>
              <h1 className='publisherHeading'>{list.title}</h1>
            </div>
          </div>
          <div className='productDescription' dangerouslySetInnerHTML={{ __html: converter.makeHtml(list.description) }}></div>
        </div>


      </div>
      <hr />
      <h3>Products</h3>
      <div className='lowerGrid'>
        {list.products && list.products.map((product, index) =>
          <ProductListItem key={product._id} product={product} context='list' />
        )}
      </div>

    </div>
  )
}