import { TextControl, TextAreaControl } from '../InputControls';
import AddressForm from '../AddressForm';
import React, { useState, useEffect } from 'react';
import './style.css';
import { makeRequest } from '../../Utils';
import { messageState } from '../../atoms';
import { useRecoilState } from 'recoil';

export default function ApplyFormRetail() {
  const [storeName, setStoreName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useRecoilState(messageState);
  const [phone, setPhone] = useState('');
  const [sent, setSent] = useState(false);
  const [address, setAddress] = useState({});
  const [website, setWebsite] = useState('');
  const [application, setApplication] = useState('')

  const sendMessage = async () => {
    let data = {
      email: email,
      name: storeName,
      application: application,
      website: website,
      type: 'Retailer',
      status: 'Pending',
      contact: {
        name: name,
        email: email,
        phone: phone
      },
      address: address
    };
    if (!email || !storeName || !website || !application) {
      setMessage({ type: 'error', label: 'oops!', text: 'Please fill out all required fields', temp: true })
      return
    }
    const request = await makeRequest('application', 'POST', data);
    if (request.ok) {
      setSent(true);
    } else if (request.accountExists) {
      setMessage({ type: 'error', label: 'oops!', text: 'It looks like you already have an account with us! Try logging in.', temp: true })
    }
  }

  if (sent) {
    return (
      <div className='contactForm'>
        <h3 className='contactForm__header'>Apply for a Retailer Account</h3>
        <p>Thanks for your application! We'll get back to you shortly!</p>
      </div>
    )
  }

  return (
    <div className='contactForm'>
      <h3 className='contactForm__header'>Apply for a Retailer Account</h3>
      <p>We aim to get back to you within 2 business days.</p>
      <TextControl
        value={storeName}
        change={(value) => {
          setStoreName(value)
        }}
        label={`Store/Venue Name`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={website}
        change={(value) => {
          setWebsite(value)
        }}
        label={`Store/Venue Website`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={name}
        change={(value) => {
          setName(value)
        }}
        label={`Your Name`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={email}
        change={(value) => {
          setEmail(value)
        }}
        label={`Your Email`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={phone}
        change={(value) => {
          setPhone(value)
        }}
        label={`Your Phone Number`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <TextAreaControl
        value={application}
        change={setApplication}
        label={`Tell us about your business!`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
        rows={3}
      />

      <AddressForm address={address} setAddress={setAddress} />

      <button className='buttonPrimary' onClick={sendMessage}>Submit</button>
    </div>
  )
}