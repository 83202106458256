import React from 'react';
import './style.css';

export default function GhostProductListItem() {
  return (
    <div className={`productContainer`}>
      <div className='productItem'>
        <div className='productItem_head'>
          <div className='productDate'><GhostText height={13} /></div>
          <div className='productIcons'>
          </div>
        </div>
        <div className={`productItem__image`}>
          <GhostImage />
        </div>
        <div className='productItem__info'>
          <div className='productItem__left'>
            <div className='productItem__publisher'>
              <GhostText height={13} />
            </div>
            <div className='productItem__title'>
              <GhostText height={22} rows={2} />
            </div>
            <div className='productItem__authors'><GhostText height={17} /></div>
          </div>
          <div className='productItem__right'>
            <div className='productItemPrice'>
              <div className='productItemPrice__retail'>
                <GhostText height={22} />
              </div>
            </div>
          </div>
        </div>
        <div className='productItem__bottom'>
          <div>
          </div>
          <div className='productItem__controls'>
          </div>
        </div>
      </div>
    </div>
  )
}

function GhostText({ height, rows = 1 }) {
  return (
    <div className='ghostTextContainer'>
      <div className='shimmer' style={{ width: `96%`, height: height }}></div>
      {rows === 2 && <div className='shimmer ghostMargin' style={{ width: `68%`, height: height }}></div>}
    </div>
  )
}

function GhostImage() {
  return (
    <div className='ghostTextContainer'>
      <div className='shimmer ghostImage' style={{ width: '180px', aspectRatio: '11 / 17' }}></div>
    </div >
  )
}