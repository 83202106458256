import moment from "moment";
import { prettyIsbn } from "../../Utils";

export const exportProducts = (products) => {
  let csv = '';
  csv += 'ID,'
  csv += 'TITLE,'
  csv += 'PUBLISHER,'
  csv += 'PUB_DATE,'
  csv += 'AUTHOR_ONE,'
  csv += 'AUTHOR_TWO,'
  csv += 'TRANSLATOR,'
  csv += 'PRICE,'
  csv += 'DISCOUNT,'
  csv += 'ONHAND\n'

  products.forEach((product) => {
    csv += prettyIsbn(product._id, '-');
    csv += ',';
    csv += product.title.replaceAll(',', ' ').replaceAll('#', '');
    csv += ',';
    csv += product.publisherData.name.replace(',', '');
    csv += ',';
    csv += moment(product.publicationDate).format('YYYY-MM-DD');
    csv += ',';
    csv += product.authors[0] ? product.authors[0] : '';
    csv += ',';
    csv += product.authors[1] ? product.authors[1] : '';
    csv += ',';
    csv += product.translators[0] ? product.translators[0] : '';
    csv += ',';
    csv += product.prices.retail.toFixed(2);
    csv += ',';
    csv += product.prices.wholesaleDiscount;
    csv += ',';
    csv += product.inventory.asterism;
    csv += '\n'
  });

  return csv;
}

