export const returnStructuredData = (product) => {

  return `
{
      "@context": "https://schema.org/",
      "@type": "Product",
      "sku": "${product._id}",
      "image": [
        "${product.cover['300']}",
      ],
      "name": "${product.title}",
      "description": "${product.description}",
      "brand": {
        "@type": "Brand",
        "name": "${product.publisherData.name}"
      },
      "offers": {
        "@type": "Offer",
        "url": "${window.location.href}",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "priceSpecification": {
          "@type": "PriceSpecification",
          "price": ${product.prices.retail},
          "priceCurrency": "USD"
        }
      }
    }
`
}