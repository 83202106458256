import React from 'react';
import { useRecoilState } from 'recoil';
import { cartState } from '../../atoms';
import { updatedCart } from '../../Utils';

export default function GiftCards() {
  const [cart, setCart] = useRecoilState(cartState);

  const addToCart = () => {
    let product = {
      _id: 'giftcard-50',
      title: 'Asterism Books $50 Gift Card',
      authors: ['Asterism Books'],
      dimensions: {
        weight: 2,
      },
      prices: {

      },

    }
    setCart(updatedCart(cart, product, 1));
  }

  return (
    <div>
      <div>
        <h3>$50 Gift Card (red)</h3>
        <button className='buttonPrimary'>Add to Cart</button>
      </div>
    </div>
  )
}