import React, { useState, useEffect, useRef } from 'react';
import { getProduct, makeRequest, returnPrice, uuid } from '../../Utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartCountState, cartState, cartTotalState } from '../../atoms';
import { ReactComponent as Close } from '../../icons/close.svg';
import './style.css';
import { BinaryControl, NumberControl, PublisherControl, TextControl } from '../../components/InputControls';
import { SearchControl } from '../../components/Search';
import { defaultOrder } from '../../Defaults';
import { prepareItems, prepareShipmentsAndSplits } from '../../components/Cart/cartUtils';

export default function Checkout() {
  const [cart, setCart] = useRecoilState(cartState);
  const cartCount = useRecoilValue(cartCountState);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [collect, setCollect] = useState(true);
  const [mode, setMode] = useState('retail');
  const [email, setEmail] = useState('');
  const [term, setTerm] = useState('');
  const cartTotal = useRecoilValue(cartTotalState);
  const [newTitle, setNewTitle] = useState('');
  const [newTitlePub, setNewTitlePub] = useState('');
  const [newList, setNewList] = useState(0);
  const [newId, setNewId] = useState('');
  const [customerData, setCustomerData] = useState({});
  const [address, setAddress] = useState({
    country: 'US'
  });


  const addToCart = (product) => {
    let newCart = { ...cart };
    if (newCart[product._id]) {
      let newItem = { ...newCart[product._id] };
      newItem.count = newCart[product._id].count + 1;
      newCart[product._id] = newItem;
    } else {
      newCart[product._id] = {
        count: 1,
        data: product
      }
    }
    setCart(newCart);
  }

  const addNewToCart = () => {
    let product = {
      _id: newId ? newId : uuid(),
      title: newTitle,
      publisher: newTitlePub,
      publicationDate: new Date().toISOString(),
      oneOff: true,
      prices: {
        retail: newList
      },
      inventory: {
        asterism: 100,
        locations: []
      },
      format: 'Paperback',
      authors: []
    }
    addToCart(product);
    setNewId('');
    setNewList(0);
    setNewTitle('');
    setNewTitlePub('');
  }

  /**
  * Removes an item from the cart
  * @param {string} id 
  */
  const removeItem = (id) => {
    let items = { ...cart };
    delete items[id];
    setCart(items);
  }

  const cancelTransaction = () => {
    makeRequest('cancel-reader-action');
  }

  const updateQuantity = (id, quantity) => {
    let items = { ...cart };
    let item = { ...items[id] };
    item.count = quantity;
    items[id] = item;
    setCart(items);
  }

  const updateCondition = (id, isNew) => {
    let items = { ...cart };
    let item = { ...items[id] };
    item.isUsed = !isNew;
    items[id] = item;
    setCart(items);
  }

  const searchRef = useRef();

  const setId = async (id) => {
    let product = await getProduct(id);
    if (product) {
      addToCart(product);
      setTerm(term === id ? '' : id);
    }
  }

  /** 
 * Gets the total cost minus shipping for the cart
 */
  const getCartTotal = () => {
    if (!Object.values(cart)) {
      return 0;
    }
    let theTotal = 0;
    Object.values(cart).forEach((item) => {
      let unitPrice = returnPrice(item.data, 'retail');
      if (item.isUsed) {
        unitPrice = unitPrice * .5;
      }
      theTotal += (unitPrice * item.count);
    });
    return theTotal;
  }

  const checkout = async (payPalData = false) => {
    setIsCheckingOut(true);
    // Prepare the big old order object
    let order = { ...defaultOrder };
    order.customer.id = '';
    order.customer.type = 'retail';
    order.customer.email = email;
    order.customer.phone = '';
    order.customer.address = {
      name: 'Asterism Warehouse Sale',
      lineOne: '568 1st Ave S, Ste 120',
      lineTwo: '',
      city: 'Seattle',
      state: 'WA',
      postalCode: '98104',
      country: 'US'
    };
    order.subtotal = getCartTotal();
    order.total = getCartTotal();
    order.itemCount = cartCount;
    order.created = new Date().toISOString();
    order.type = 'retail';
    order.inPerson = true;
    order.prepayDiscount = (mode === 'wholesale' || mode === 'library') && collect;
    // Prepare all of the packages we're gonna need to ship
    const orderData = prepareShipmentsAndSplits(order.total, cart, 0, 0, 'retail', false, 'US', null, collect);
    order.shipments = orderData.shipments;
    order.shipments.forEach((shipment, index) => {
      order.shipments[index].status = 'Shipped';
      order.shipments[index].tracking = 'In-person pickup';
    });
    order.onSite = true;
    order.coupon = '';
    order.payments = orderData.payments;
    order.shippingSplit = orderData.shippingSplit;
    order.entities = Object.keys(order.payments);
    order.shipping.amount = 0;
    order.shipping.method = 'n/a';
    order.shipping.consolidate = false;
    // Prepare the data of the items in the cart
    order.items = prepareItems(cart, 'retail', null, order.prepayDiscount, null);
    let data = {
      order: order, // Order data
      createAccount: false, // Are we creating an account?
      currentPage: window.location.href, // We pass this to Stripe in case the user cancels
    }

    await makeRequest('physical-checkout', 'POST', data); // Make the request!
    setIsCheckingOut(false);
  }

  const newTransaction = () => {
    setCart({});
    setEmail('');
  }

  return (
    <div>
      <div className='checkoutPage' style={{ gridTemplateColumns: '3fr 1fr' }}>
        <div>
          <div className='roomBelowLarge asterismCard'>
            <SearchControl term={term} label={'Scan/Add a Product'} searchref={searchRef} text='select' eagerSendId={true} selected={[]} classList={'larger'} addNew={(value) => { setId(typeof value == 'string' ? value : value.asterismId) }} />
          </div>
          <div className='checkoutItems'>
            {Object.values(cart).map((item) =>
              <div key={item.data._id} className='checkoutCartItem'>
                <div>
                  <div className='checkoutCartItem__image'>
                    <img src={item.data.cover ? item.data.cover['300'] : 'https://asterismbooks.com/asterism.png'} alt={`${item.data.title} book cover`} />
                  </div>
                </div>
                <div className={'checkoutCartItem__field checkoutCartItem__title'}>
                  <div className='checkoutCartItem__content'>
                    <div>{item.data.title}</div>
                    <div className='checkoutCartItem__author'>{item.data.authors.length == 1 ? item.data.authors[0] : item.data.authors.length > 1 ? `${item.data.authors[0]}, et al` : ''}</div>
                  </div>
                </div>
                <div className={'checkoutCartItem__field'}>
                  <div className='checkoutCartItem__content'>
                    ${returnPrice(item.data, mode, null, false, item.isUsed).toFixed(2)}/each
                  </div>
                </div>
                <div className={'checkoutCartItem__field'}>
                  <div className='checkoutCartItem__content checkoutCartItem__controls'>
                    <button className='checkoutControlButton' onClick={() => { updateQuantity(item.data._id, item.count === 0 ? 0 : item.count - 1) }}><i className='fa-solid fa-minus'></i></button>
                    <div className='checkoutControl__count'>{item.count}</div>
                    <button className='checkoutControlButton' onClick={() => { updateQuantity(item.data._id, item.count + 1) }}><i className='fa-solid fa-plus'></i></button>
                    <button aria-label='Remove item from cart' disabled={isCheckingOut} className='checkoutControlButton checkoutControlButton-trash' onClick={() => { removeItem(item.data._id) }}><i className='fa-solid fa-trash'></i></button>
                  </div>
                </div>
                <div className={'checkoutCartItem__field'}>
                  <div className='checkoutCartItem__content'>
                    <BinaryControl options={['Used', 'New']} value={item.isUsed ?? false} change={(value) => { updateCondition(item.data._id, !value) }} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='asterismCard roomAboveLarge'>
            <h3 className='roomBelowMedium'>Add Arbitrary Product</h3>
            <TextControl
              value={newTitle}
              classlist={'larger'}
              label='New Product Title'
              change={setNewTitle}
            />
            <TextControl
              value={newId}
              classlist={'larger'}
              label='New Product ID'
              change={setNewId}
            />
            <NumberControl
              value={newList}
              change={setNewList}
              classlist={'larger'}
              step={0.01}
              label={'New Product List Price'}
            />
            <PublisherControl
              value={newTitlePub}
              change={setNewTitlePub}
              label='New Product Publisher'
              classlist={'larger'}
            />
            <button className='buttonPrimary' onClick={addNewToCart}>Add to Cart</button>
          </div>
        </div>
        <div>
          <div className='asterismCard'>
            <TextControl
              value={email}
              classlist={'larger'}
              label='Customer Email (optional)'
              help='Collect if they need a receipt'
              change={setEmail}
            />
          </div>
          <div className='checkoutSummary asterismCard'>
            <div className='checkoutSummary__item'><span className='checkoutSummary__label'>Subtotal:</span> ${getCartTotal().toFixed(2)}</div>
            <div className='checkoutSummary__item'><span className='checkoutSummary__label'>Sales Tax:</span> ${(getCartTotal() * 0.1035).toFixed(2)}</div>
            <div className='checkoutSummary__item'><span className='checkoutSummary__label'>Total:</span> ${(getCartTotal() * 1.1035).toFixed(2)}</div>
          </div>
          <div className='checkoutFinalControl'>
            <button className='cartButton roomBelowMedium' disabled={cartCount === 0} onClick={checkout}>Checkout</button>
            <button className='secondaryCartButton roomBelowMedium' onClick={newTransaction}>New Transaction</button>
            <button className='tertiaryCartButton' onClick={cancelTransaction}>Cancel Transaction</button>
          </div>
        </div>
      </div>
    </div>
  )
}