import React, { useEffect, useState } from 'react';
import './style.css';

export default function Paginator({ value, change, maxPages }) {

  if (isNaN(maxPages)) {
    return (<></>)
  }

  return (
    <div className='paginator'>
      <button disabled={value === 0 ? 'disabled' : ''} onClick={() => { change(0); window.scrollTo(0, 0); }}><span>&#xab;</span></button>
      <button disabled={value === 0 ? 'disabled' : ''} onClick={() => { change(value - 1); window.scrollTo(0, 0); }}><span>&#8249;</span></button>
      <div className='paginator__label'><span>Page {value + 1} of {maxPages}</span></div>
      <button disabled={value === (maxPages - 1)} onClick={() => { change(value + 1); window.scrollTo(0, 0); }}><span>&#8250;</span></button>
      <button disabled={value === (maxPages - 1)} onClick={() => { change(maxPages - 1); window.scrollTo(0, 0); }}><span>&#xbb;</span></button>
    </div>
  )
}
