import React from 'react';
import { Link } from 'react-router-dom';
import { prepareAuthorList } from '../../Utils';

export default function PreviewModal({ product, close }) {
  return (
    <div className='previewModal__wrapper'>
      <div className='previewModal__content'>
        <div className='previewModal__header'>
          <div className='previewHeader_data'>
            <div className='productItem__publisher'><Link to={`/publisher/${product.publisherData.slug}`}>{product.publisherData.name}</Link></div>
            <Link to={`/product/${product.slug}`} className='productItem__title'>{product.title}</Link>
            <div className='productItem__authors'>{prepareAuthorList(product)}</div>
          </div>
          <div>
            <button className='closeButton' onClick={close}><i className='fa-solid fa-xmark'></i></button>
          </div>
        </div>
        <object
          data={product.preview}
          width="100%"
          height="92%">
        </object>
      </div>
    </div>
  )
}