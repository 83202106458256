import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApplyFormLibrary from '../../components/ApplyFormLibrary';

export default function PageAbout() {

  document.title = `Asterism for Libraries | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `We love partnering with libraries. Learn how to sign up for a library account to receive 20% off all orders, or take advantage of standing orders.`);


  return (
    <div>
      <h1 className='page-header'>For Libraries</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>
          Greetings, librarian! We would love to have you join Asterism to order books for your venerable shelves. We offer a 20% discount on all orders to our library partners, as well as the ability to create standing orders to receive copies of all titles from any or all of our publishers! If you'd like to get started, just fill out a quick application.
        </div>
        <div className='flex-two'>
          <ApplyFormLibrary />
        </div>
      </div>
    </div>
  )
}
