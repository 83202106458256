import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ProductListItem from '../../components/ProductListItem';
import { makeRequest, Loading, firstFeaturedIndex } from '../../Utils';
import Paginator from '../../components/Paginator';
import showdown from 'showdown';
import './style.css';

const converter = new showdown.Converter();

export default function Publisher() {
  const [profile, setProfile] = useState({});
  const [lists, setLists] = useState([]);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [featured, setFeatured] = useState(-1);

  useEffect(() => {
    let featuredIndex = firstFeaturedIndex(products);
    setFeatured(featuredIndex);
  }, [products])

  const navigate = useNavigate();

  const per = 20;

  const { slug, page } = useParams();

  const getProducts = async (id) => {
    let data = await makeRequest(`publisher-products?pub=${id}&per=${per}&page=${page ? page - 1 : 0}`);
    setProducts(data.products);
    setTotal(data.count);
    setLoading(false);
  }

  const getLists = async (id) => {
    let data = await makeRequest(`lists?pub=${id}`);
    setLists(data);
  }

  const getProfile = async () => {
    let data = await makeRequest(`publisher-by-slug?slug=${slug}`, 'GET');
    if (!data._id) {
      navigate('/404');
    }
    document.title = `${data.name} | Asterism Books`;
    document.querySelector('meta[name="description"]').setAttribute('content', `${profile.description}`);

    setProfile(data);
    getLists(data._id);
    getProducts(data._id);
  }

  useEffect(() => {
    setLoading(true);
    setProducts([]);
    getProfile();
  }, [page]);

  const updatePage = (page) => {
    if (!page || page == 0) {
      navigate(`/publisher/${slug}`)

    } else {
      navigate(`/publisher/${slug}/page/${parseInt(page) + 1}`)

    }
  }

  return (
    <div>
      <div>
        {!page && <div>
          <div className='publisherHeader'>
            {profile.logo &&
              <div className='publisherLogo'>
                <img className='publisherLogo__image' src={profile.logo['600']} alt={`${profile.name} logo`} />
              </div>
            }
            <div>
              <div className='publisherLocation'>{profile.location}</div>
              <h1 className='publisherHeading'>{profile.name}</h1>
              <div className='publisherLinks'>
                <a target='_blank' href={profile.website}>Official Website</a>
                {profile.twitter && <span> | <a target='blank' href={`https://twitter.com/${profile.twitter.replace('@', '')}`}>Twitter</a></span>}
                {profile.instagram && <span> | <a target='blank' href={`https://instagram.com/${profile.instagram.replace('@', '')}`}>Instagram</a></span>}

              </div>
            </div>
          </div>
          <div className='productDescription' dangerouslySetInnerHTML={{ __html: converter.makeHtml(profile.description) }}></div>
          {lists && lists.length > 0 &&
            <div>
              <div className='listOfLists'>
                {lists.map((list) =>
                  <div key={list._id} className='pubListItem'>
                    {list.image && <div className='pubListItem__image'><img src={list.image['300']} /></div>}
                    <div className='pubListItem__info'>
                      <div className='pubListItem__name'><Link to={`/list/${list.slug}`}>{list.title}</Link></div>
                      <div className='pubListItem__meta'>{list.products.length} {list.products.length == 1 ? 'item' : 'items'}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }

        </div>
        }

      </div>
      <hr />
      <h3>All {profile.name} Titles {page && page > 0 ? ` - Page ${page}` : ''}</h3>
      {loading && <div className='loadingMessage'><Loading /> <span className='loadingMessage__text'>Loading titles...</span></div>}

      <div className='lowerGrid'>
        {products.map((product, index) =>
          <ProductListItem key={product._id} product={product} featured={index === featured} context='publisher' />
        )}
      </div>
      <div className='roomAboveMedium'>
        <Paginator value={page ? parseInt(page) - 1 : 0} maxPages={Math.ceil(total / per)} change={updatePage} />
      </div>

    </div>
  )
}
