import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cartState, modeState, messageState, sessionState, pageviewState } from '../../atoms';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { canOrderProduct, returnPrice, isOnSale, prepareAuthorList, analyticsEvent, makeRequest, updatedCart, asaEvent, slugify } from '../../Utils';
import './style.css';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function ProductListItemSmall({ product, featured = false, order = null, context }) {
  const mode = useRecoilValue(modeState);
  const [cart, setCart] = useRecoilState(cartState);
  const setMessage = useSetRecoilState(messageState);
  const [expanded, setExpanded] = useState(featured);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);


  const addToCart = () => {
    setCart(updatedCart(cart, product));
    setMessage({ type: 'success', label: 'Added to Cart', text: `<strong>${product.title}</strong> has been added to your cart!`, img: product.cover['300'], temp: false, cart: true });
    analyticsEvent('addToCart', { product: product._id, location: 'productList', mode: mode });
    asaEvent({
      session: session.id,
      category: 'cart',
      action: 'addToCart',
      label: product._id,
      locationOne: `${context.block}_${context.index}_${slugify(context.title)}`,
      locationTwo: 'productCard_small',
      locationThree: 'button',
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
      value: 1
    });
  }

  const pcEvent = (category, action, element) => {
    asaEvent({
      session: session.id,
      category: category,
      action: action,
      label: product._id,
      locationOne: 'homepage',
      locationTwo: `${context.block}_${context.index}_${context.title}`,
      locationThree: element,
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
    });
  }

  const prepareQuote = (quote) => {
    return converter.makeHtml(quote).replace(/[“”]/g, '').replace(/"/g, '')
  }

  useEffect(() => {
    setExpanded(featured);
  }, [featured]);


  return (
    <div className={`productContainer productContainer_smaller`}>
      <div className='productItem'>
        <div className={`productItem__image ${expanded && 'featuredImage'}`}>
          {isOnSale(product, mode) && <div className='onSaleBanner'>On Sale</div>}
          {product.cover && <Link to={`/product/${product.slug}`} onClick={() => { pcEvent('recirc', 'click', 'productCover') }}><img src={product.cover['300']} alt={product.title} /></Link>}
        </div>
        {expanded && product.featuredQuote && <div className='productItem__featuredPull'>
          <div className='arrowRight'></div>
          <div className={`featuredQuotation ${product.featuredQuote.length < 160 && 'larger'}`} dangerouslySetInnerHTML={{ __html: prepareQuote(product.featuredQuote) }}></div>
          <div className='featuredAttribution'>{product.featuredAttribution}</div>
        </div>}
        <div className='productItem__info'>
          <div className='productItem__left'>
            <div className='productItem__publisher'><Link to={`/publisher/${product.publisherData.slug}`} onClick={() => { pcEvent('recirc', 'click', 'publisherName') }}>{product.publisherData.name}</Link></div>
            <Link to={`/product/${product.slug}`} className='productItem__title productItem__title-small' onClick={() => { pcEvent('recirc', 'click', 'productTitle') }}>{product.title}</Link>
            <div className='productItem__authors'>{prepareAuthorList(product)}</div>
          </div>
          <div className='productItem__right'>

          </div>
        </div>
        <div className='productItem__bottom'>
          <div>
            <div className='productItemPrice'>
              <div className='productItemPrice__retail'>${returnPrice(product, mode).toFixed(2)}</div>
              {canOrderProduct(product, mode) && mode === 'wholesale' && <div className='productItemPrice__wholesale'>{product.prices.wholesaleDiscount}% off</div>}
            </div>
          </div>
          <div className='productItem__controls'>
            <button className='productControl__add productControl_add-smaller' disabled={!canOrderProduct(product, mode)} onClick={addToCart}>Add <i className='fa-solid fa-plus'></i></button>
          </div>
        </div>
      </div>
    </div>
  )
}