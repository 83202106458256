export const defaultOrder = {
  customer: {
    id: '',
    type: '',
    email: '',
    address: {}
  },
  customerRef: '',
  status: 'Pending',
  type: 'retail',
  subtotal: 0,
  items: {},
  shipping: {
    amount: 0,
    method: ''
  },
  shipments: [],
  notes: '',
  messages: [],
  payments: {}
}

const categories = [
  {
    label: 'Poetry',
    value: 'poetry'
  },
  {
    label: 'Comics & Graphic Novels',
    value: 'comics-graphic-novels'
  },
  {
    label: 'Poetics',
    value: 'poetics'
  },
  {
    label: 'Short Stories',
    value: 'short-stories'
  },
  {
    label: 'Compilation',
    value: 'compilation'
  },
  {
    label: 'Fiction',
    value: 'fiction'
  },
  {
    label: 'Non-Fiction',
    value: 'non-fiction'
  },
  {
    label: 'Essay',
    value: 'essays'
  },
  {
    label: 'Artist Book',
    value: 'artist-book'
  },
  {
    label: 'Hybrid',
    value: 'hybrid'
  },
  {
    label: 'Performance',
    value: 'performance'
  },
  {
    label: 'Art/Visual',
    value: 'art-visual'
  },
  {
    label: 'Novella',
    value: 'novella'
  },
  {
    label: 'Novel',
    value: 'novel'
  },
  {
    label: 'Queer Lit',
    value: 'queer-lit'
  },
  {
    label: 'Queer Studies',
    value: 'queer-studies'
  },
  {
    label: 'Theater/Drama',
    value: 'theater-drama'
  },
  {
    label: 'Theory',
    value: 'theory'
  },
  {
    label: 'Translation Studies',
    value: 'translation-studies'
  },
  {
    label: 'Philosophy',
    value: 'philosophy'
  },
  {
    label: 'Cinema',
    value: 'cinema'
  },
  {
    label: 'Magazines & Periodicals',
    value: 'magazines'
  },
  {
    label: 'Anthology',
    value: 'anthologies'
  },
  {
    label: 'Criticism',
    value: 'criticism'
  },
  {
    label: 'Biography',
    value: 'biography'
  },
  {
    label: 'Memoirs',
    value: 'memoir'
  },
  {
    label: 'Translations',
    value: 'translation'
  },
  {
    value: 'humor',
    label: 'Humor'
  },
  {
    value: 'horror',
    label: 'Horror'
  },
  {
    value: 'crime',
    label: 'Crime'
  },
  {
    value: 'indigenous',
    label: 'Indigenous'
  },
  {
    label: 'Environment/Ecology',
    value: 'environment'
  },
  {
    value: 'love-and-sex',
    label: 'Love & Sex'
  },
  {
    value: 'occult',
    label: 'Occult'
  },
  {
    value: 'music',
    label: 'Music'
  },
  {
    value: 'conceptual-writing',
    label: 'Conceptual Writing'
  },
  {
    value: 'border-studies',
    label: 'Border Studies'
  },
  {
    value: 'latinx-studies',
    label: 'Latinx Studies'
  },
  {
    value: 'technology',
    label: 'Technology'
  }, {
    value: 'asian-studies',
    label: 'Asian Studies'
  }, {
    value: 'black-studies',
    label: 'Black Studies'
  }
];


export const catRef = {
  'poetry': {
    label: 'Poetry',
    value: 'poetry'
  },
  'short-stories': {
    label: 'Short Stories',
    value: 'short-stories'
  },
  'comics-graphic-novels': {
    label: 'Comics & Graphic Novels',
    value: 'comics-graphic-novels'
  },
  'compilation': {
    label: 'Compilation',
    value: 'compilation'
  },
  'fiction': {
    label: 'Fiction',
    value: 'fiction'
  },
  'non-fiction': {
    label: 'Non-Fiction',
    value: 'non-fiction'
  },
  'essays': {
    label: 'Essay Collections',
    value: 'essays'
  },
  'hybrid': {
    label: 'Hybrid',
    value: 'hybrid'
  },
  'art-visual': {
    label: 'Art/Visual',
    value: 'art-visual'
  },
  'novella': {
    label: 'Novella',
    value: 'novella'
  },
  'novel': {
    label: 'Novel',
    value: 'novel'
  },
  'theater-drama': {
    label: 'Theater/Drama',
    value: 'theater-drama'
  },
  'theory': {
    label: 'Theory',
    value: 'theory',
  },
  'philosophy': {
    label: 'Philosophy',
    value: 'philosophy'
  },
  'cinema': {
    label: 'Cinema',
    value: 'cinema'
  },
  'biography': {
    label: 'Biography',
    value: 'biography'
  },
  'magazines': {
    label: 'Magazines & Periodicals',
    value: 'magazines'
  },
  'anthologies': {
    label: 'Anthologies',
    value: 'anthologies'
  },
  'criticism': {
    label: 'Criticism',
    value: 'criticism'
  },
  'translation': {
    label: 'Translations',
    value: 'translation'
  },
  'translation-studies': {
    label: 'Translation Studies',
    value: 'translation-studies'
  },
  'memoir': {
    value: 'memoir',
    label: 'Memoirs'
  },
  'poetics': {
    label: 'Poetics',
    value: 'poetics'
  },
  'humor': {
    value: 'humor',
    label: 'Humor'
  },
  'artist-book': {
    label: 'Artist Book',
    value: 'artist-book'
  },
  'performance': {
    label: 'Performance',
    value: 'performance'
  },
  'queer-studies': {
    label: 'Queer Studies',
    value: 'queer-studies'
  },
  'queer-lit': {
    label: 'Queer Lit',
    value: 'queer-lit'
  },
  'horror': {
    label: 'Horror',
    value: 'horror'
  },
  'crime': {
    value: 'crime',
    label: 'Crime'
  },
  'indigenous': {
    value: 'indigenous',
    label: 'Indigenous'
  },
  'environment': {
    label: 'Environment/Ecology',
    value: 'environment'
  },
  'love-and-sex': {
    value: 'love-and-sex',
    label: 'Love & Sex'
  },
  'music': {
    value: 'music',
    label: 'Music'
  },
  'occult': {
    value: 'occult',
    label: 'Occult',
  },
  'technology': {
    value: 'technology',
    label: 'Technology'
  },
  'asian-studies': {
    value: 'asian-studies',
    label: 'Asian Studies'
  },
  'black-studies': {
    value: 'black-studies',
    label: 'Black Studies'
  },
  'latinx-studies': {
    value: 'latinx-studies',
    label: 'Latinx Studies'
  },
  'conceptual-writing': {
    value: 'conceptual-writing',
    label: 'Conceptual Writing'
  },
  'border-studies': {
    value: 'border-studies',
    label: 'Border Studies'
  },
};

export const cats = categories.sort((a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
});