import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { TextControl } from '../../components/InputControls';
import { checkAuth, makeRequest, wishlistChange, analyticsEvent } from '../../Utils';
import { userState, messageState, cartState } from '../../atoms';

export default function Login({ context }) {
  const [email, setEmail] = useState('');
  const setCart = useSetRecoilState(cartState);
  const [pass, setPass] = useState('');
  const [userData, setUserData] = useRecoilState(userState);
  const setMessage = useSetRecoilState(messageState);
  const [forgot, setForgot] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [passView, setPassView] = useState(false);

  document.title = `Log in to your ${context === 'publisher' ? 'publisher account' : 'account'} | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', 'Login to your account to view your profile, orders, and more.');

  const navigate = useNavigate();

  const forgotPass = async () => {
    let data = await makeRequest('forgot', 'POST', { email: email, context: context });
    if (data.ok) {
      setMessage({ type: 'success', label: 'success', text: 'We will send you an email with a link to reset your password!', temp: true });
      analyticsEvent('loginForgot');
    }
  }

  const submit = async () => {
    setLoggingIn(true);
    let user = {
      email: email,
      pass: pass,
      mode: context
    }
    let data = await makeRequest('login', 'POST', user);
    if (data.token) {
      setMessage({ type: 'success', label: 'success', text: 'User logged in successfully!', temp: true });
      localStorage.setItem('asterismToken', data.token);
      //const queryParams = new URLSearchParams(window.location.search);
      //const ret = queryParams.get('return');
      let authData = await checkAuth(data.token);
      setUserData(authData.userData);
      if (authData.cart) {
        setCart(authData.cart);
      }
      analyticsEvent('loginSuccess');
      if (context === 'publisher') {
        navigate('/pub/account');
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('wishlist')) {
          await wishlistChange(authData.id, searchParams.get('wishlist'), true);
          navigate('/account/wishlist');
        } else {
          navigate('/account');
        }
      }
    } else {
      setMessage({ type: 'error', temp: true, text: data.message, label: 'Error' })
      analyticsEvent('loginError');
    }
    setLoggingIn(false)
  }

  if (userData.id) {
    return (
      <div>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/account'}>your account</Link></h4>
      </div>
    )
  }

  return (
    <div className='loginCard'>
      <div className='roomBelowMedium'>
        <h1 className='page-header'>Sign in to your Asterism account</h1>
      </div>

      {context !== 'publisher' && <div className='roomBelowMedium'><Link to={`/create-account${window.location.search}`}>Or create an account</Link></div>}

      <div className='loginForm'>
        <TextControl
          value={email}
          change={(value) => { setEmail(value) }}
          label={`Account Email`}
          disabled={loggingIn}
          valid={false}
          type='text'
          autocomplete='username'
          required={true}
          bypass={true}
          description={''}
        />
        {!forgot &&
          <div className='inputControl'>
            <div className='inputControl__label'>Password</div>
            <div className='passwordContainer'>
              <input disabled={loggingIn} type={passView ? 'text' : 'password'} value={pass} onChange={(e) => { setPass(e.target.value) }} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submit();
                }
              }} />
              <button onClick={() => { setPassView(!passView) }}><i class={passView ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'} name='Toggle password visibility'></i></button>
            </div>
          </div>
        }
      </div>

      {!forgot && <div className='buttonPair'>
        <button className='buttonPrimary' disabled={loggingIn} onClick={submit}>Sign In</button>
        <button className='buttonSecondary' onClick={() => { setForgot(true) }}>Forgot Password</button>
      </div>}
      {forgot && <div className='buttonPair'>
        <button className='buttonPrimary' disabled={loggingIn} onClick={forgotPass}>Request Reset Email</button>
        <button className='buttonSecondary' onClick={() => { setForgot(false) }}>Login Instead</button>
      </div>}
    </div>
  )
}
