import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApplyFormPublisher from '../../components/ApplyFormPublisher';

export default function PageAbout() {

  document.title = `Asterism for Publishers | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `If you're a bookstore, Asterism would love to work with you! Read about our terms and conditions and apply for an account.`);


  return (
    <div>
      <h1 className='page-header'>For Prospective Publishers</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>

          <p>Thank you for your interest in Asterism Books! We welcome applications from prospective publishers who want to sell and distribute their titles with us. We focus primarily on literary presses publishing fiction, poetry, essays, criticism, and art books. The publishers we work with tend to produce books that are not only great reads but also designed and printed well.</p>

          <p>We’re looking for publishers who:</p>

          <ul>
            <li>Publish multiple authors besides the publisher themselves</li>
            <li> Have a track record of published materials</li>
            <li>Have a clear vision and timeline for future projects</li>
            <li>Are actively engaged in marketing their books</li>
            <li>Are able to send us materials in a timely fashion</li >
          </ul>
          <p>If you are a new press, please be prepared to share plans for publications, marketing, and printing.</p>
          <p>We are not looking for publishers who:</p>
          <ul>
            <li>Publish books from paying clients</li >
            <li>Primarily publish the work of one author</li >
          </ul>
          <p>We ask that you take a look around our website and only apply if your press is a good fit. If your press meets these guidelines and you're still interested in joining, please read through the FAQs below before filling out the application. We aim to respond within three months. Due to an unprecedented number of inquiries, we are not able to provide detailed responses as to why we are not able to take on your press at this time.</p>


          <h2>FAQs</h2>

          <p><strong>Q: Are you exclusive?</strong></p>
          <p>A: Not at all! You are welcome to sell through other channels. We don't even have contracts, we just expect you to abide by the rules and terms while using our platform.</p>

          <p><strong>Q: So I can still maintain a direct relationship with my local bookstore?</strong></p>
          <p>Yep. We don't expect you to funnel all of your traffic through Asterism. We would, of course, love you to promote us to booksellers you already work with, and there will be some tools within Asterism for easy re-ordering that may make it worth your while to want all sales to go through us, but it's by no means required.</p>

          <p><strong>Q: How much does it cost?</strong></p>
          <p>A: It costs nothing to get setup with Asterism, and we have no recurring fees. Asterism takes 24% of the final sale price of the book. That's it!</p>

          <p><strong>Q: Who does the shipping?</strong></p>
          <p>A: We handle all fulfillment out of our Seattle warehouse full of book nerds who love (and we mean <em>love</em>) packing books.</p>

          <p><strong>Q: How often will I get paid?</strong></p>
          <p>A: Publisher payouts happen on a rolling basis, and are often instantaneous when an order is paid. It can sometimes take up to 2 days, but this should be a rare occurence.</p>

          <p><strong>Q: How do you handle returns?</strong></p>
          <p>We don't. At least not in the traditional sense. All orders are non-returnable by default, but we will take reports of damaged stock and issue refunds as necessary.</p>

          <p><strong>Q: How will I know if my stock is running low?</strong></p>
          <p>We'll send periodic stock reports when we have fewer copies of your titles than we'd like. You can also view your own stock levels in the admin.</p>

          <p><strong>Q: Are there any other charges I should know about?</strong></p>
          <p>Nope.</p>

          <p><strong>Q: Are you sure?</strong></p>
          <p>Yep.</p>

          <p><strong>Q: How do I request new features?</strong></p>
          <p>We're actively developing new features and integrations, so you can always email us at info@asterismbooks.com. We'll also be organizing quarterly town halls for publishers to talk about what we should build next!</p>

          <p><strong>Q: Who owns Asterism? Who makes decisions?</strong></p>
          <p>A: Asterism Books LLC is co-owned by Joshua Rothes of Sublunary Editions and Phil Bevis of Chatwin Books. Phil and Josh make the business decisions with the help of an advisory board that also includes Andrew Wilt from 11:11, Jesi Bender of KERNPUNKT, and Jacob Smullyan of Sagging Meniscus.</p>

        </div>
        <div className='flex-two'>
          <ApplyFormPublisher />
        </div>
      </div>
    </div>
  )
}
