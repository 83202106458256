import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { makeRequest } from '../../Utils';
import Select from 'react-select';
import showdown from 'showdown';
const converter = new showdown.Converter();

export function TextControl({ value, change, label = '', help = '', placeholder = '', valid = true, type = 'text', classlist = '', disabled = false, name = '', required = false, onEnter = null, bypass = false, autoComplete = 'off', blurEvent = null, focusEvent = null }) {
  const [localValue, setLocalValue] = useState(value);
  const [cursor, setCursor] = useState(null);

  const input = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onEnter) {
      setTimeout(() => {
        onEnter();
      }, 30)
    }
  }

  useEffect(() => {
    const ref = input.current;
    if (ref && cursor) ref.setSelectionRange(cursor, cursor);
  }, [input, value, cursor]);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for={name} className='inputControl__label'>{label} {required && <span className='required'>*</span>}</label>}
      <input
        disabled={disabled ? 'disabled' : ''}
        id={name}
        ref={input}
        autoComplete={autoComplete}
        type={type}
        className={`${valid && 'valid'} ${classlist}`}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        onBlur={() => {
          if (blurEvent) {
            setTimeout(() => {
              blurEvent();
            }, [100])
          }
        }}
        onFocus={() => {
          if (focusEvent) {
            focusEvent();
          }
        }}
        onChange={(e) => { change(e.target.value); setCursor(e.target.selectionStart); }} />
      {help && <div className='inputControl__help' dangerouslySetInnerHTML={{ __html: help }}></div>}
    </div>
  )
}


export function SelectControl({ value, change, label = '', help = '', valid = true, options = [], classlist = '', disabled = false, name = '', canBeEmpty = false }) {
  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      <select id={name} disabled={disabled ? 'disabled' : ''} className={`${valid && 'valid'}`} value={value} onChange={(e) => { change(e.target.value) }}>
        {canBeEmpty && <option value={''}>{''}</option>}
        {options.length > 0 && options.map((opt) =>
          <option key={opt.value} value={opt.value}>{opt.label}</option>
        )}
      </select>
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function CustomerControl({ value, change, label = '', help = '', valid = true, options = [], classlist = '', disabled = false, name = '' }) {
  const [customers, setCustomers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(true);

  const getCustomers = async () => {
    let data = await makeRequest(`customers?context=publisher&per=${500}&page=0`, 'GET');
    let formatted = data.customers.map((customer) => {
      return {
        label: customer.name,
        value: customer._id
      }
    });
    setCustomers([{ label: '', value: '' }].concat(formatted));
  }

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (!search) {
      setMatches([]);
    } else {
      setShow(true);
      setMatches(customers.filter((customer) => { return customer.label.toLowerCase().includes(search.toLowerCase()) }).slice(0, 10));
    }
  }, [search])

  return (
    <div className='dataListWrapper'>
      <TextControl focusEvent={() => setShow(true)} blurEvent={() => setShow(false)} value={search} change={setSearch} label={label} help={help} valid={valid} disabled={disabled} name={name} />
      {matches &&
        <div className={`matchList ${!show && 'invisible'}`}>
          {matches.map((match) =>
            <div className='matchOption' onClick={() => {
              setSearch(match.label);
              change(match.value);
              setTimeout(() => {
                setShow(false);
              }, [800])
            }}>{match.label}</div>
          )}
        </div>
      }
    </div>
  )
}

export function DataListControl({ value, change, label = '', help = '', valid = true, options = [], classlist = '', disabled = false, name = '' }) {
  const [matches, setMatches] = useState([]);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(true);


  useEffect(() => {
    if (!search) {
      setMatches([]);
    } else {
      setShow(true);
      setMatches(options.filter((option) => { return option.label.toLowerCase().includes(search.toLowerCase()) }).slice(0, 10));
    }
  }, [search])

  return (
    <div className='dataListWrapper'>
      <TextControl focusEvent={() => setShow(true)} blurEvent={() => setShow(false)} value={search} change={setSearch} label={label} help={help} valid={valid} disabled={disabled} name={name} />
      {matches &&
        <div className={`matchList ${!show && 'invisible'}`}>
          {matches.map((match) =>
            <div className='matchOption' onClick={(e) => {
              e.preventDefault();
              change(match.value);
              setSearch(match.label);
              setShow(false);
            }}>{match.label}</div>
          )}
        </div>
      }
    </div>
  )
}

export function SelectWithSearchControl({ value, change, label = '', help = '', valid = true, options = [], classlist = '', disabled = false, name = '', canBeEmpty = false }) {
  let thisValue = options.filter((option) => {
    return value === option.value;
  });

  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      <Select
        value={thisValue[0] || null}
        onChange={(value) => { change(value.value) }}
        options={options}
      />
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function PublisherControl({ value, change, label = '', help = '', valid = true, options = [], classlist = '', disabled = false, name = '', asterism = false }) {
  const [publishers, setPublishers] = useState([]);

  const getPubs = async () => {
    let data = await makeRequest('publishers', 'GET');
    let formatted = data.map((pub) => {
      return {
        value: pub.id,
        label: pub.name
      }
    })
    let options = [{ label: '', value: '' }];
    if (asterism) {
      options.push({ label: 'Asterism', value: 'asterism' })
    }
    setPublishers(options.concat(formatted));
  }

  useEffect(() => {
    getPubs();
  }, [])

  return (
    <SelectControl value={value} classlist={classlist} change={change} label={label} help={help} valid={valid} options={publishers} disabled={disabled} name={name} />
  )
}

export function BinaryControl({ value, change, label = '', help = '', options = ['Yes', 'No'], classList = null, disabled = false, name = '' }) {
  return (
    <div className={`inputControl binaryControl ${classList}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      {!disabled && <div className='binaryChooser'>
        <div role='button' tabIndex={0} aria-label='Yes' className={`binaryChooser__opt ${value === true && 'binaryChooser__opt-chosen'}`} onClick={() => { change(true) }}>{options[0]}</div>
        <div role='button' tabIndex={0} aria-label='No' className={`binaryChooser__opt ${value === false && 'binaryChooser__opt-chosen'}`} onClick={() => { change(false) }}>{options[1]}</div>
      </div>}
      {disabled && <div className='binaryChooser binaryChooser-disabled'>
        <div role='button' aria-label='Yes' className={`binaryChooser__opt ${value === true && 'binaryChooser__opt-chosen'}`}>{options[0]}</div>
        <div role='button' aria-label='No' className={`binaryChooser__opt ${value === false && 'binaryChooser__opt-chosen'}`}>{options[1]}</div>
      </div>}
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function MarkdownControl({ value, change, maxLength = 20000, label = '', help = '', placeholder = '', valid = true, type = 'text', classlist = '', disabled = false, rows = 3, required = false, preview = true, }) {
  const [localValue, setLocalValue] = useState(value);
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  const makeTags = (v) => {
    return converter.makeHtml(v);
  }

  return (
    <div className='inputControl'>
      {label && <label className='inputControl__label'>{label} {required ? <span className='required'>*</span> : ''}</label>}
      {preview &&
        <BinaryControl
          value={editing}
          change={(value) => { setEditing(value) }}
          options={['Edit', 'Preview']}
        />
      }
      <div className='markdown-container'>
        {editing && <textarea
          disabled={disabled ? 'disabled' : ''}
          rows={rows}
          type={type}
          maxLength={maxLength}
          className={`${valid && 'valid'} ${classlist}`}
          placeholder={placeholder}
          value={value && value.charAt(0) == '<' ? converter.makeMarkdown(value) : value}
          onChange={(e) => { change(e.target.value) }} />}
        {!editing && <div className='post-content product-description' dangerouslySetInnerHTML={{ __html: makeTags(value) }}></div>}
      </div>
      {editing && <div className='inputControl__help'>{help ? `${help} ` : ''}This editor uses Markdown. Unfamiliar? You can find a guide <a href='https://www.markdownguide.org/basic-syntax/' target='_blank'>here</a>.</div>}
    </div>
  )
}


export function TextAreaControl({ value, change, label = '', help = '', placeholder = '', valid = true, type = 'text', classlist = '', disabled = false, rows = 3, name = '', description = '' }) {
  return (
    <div className='inputControl'>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      {description && <p className='form-description'>{description}</p>}
      <textarea id={name} disabled={disabled ? 'disabled' : ''} rows={rows} type={type} className={`${valid && 'valid'} ${classlist}`} placeholder={placeholder} value={value} onChange={(e) => { change(e.target.value) }} />
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}


export function MultiSelect({ choices = [], change, values = [], label = '', help = '', disabled = false }) {

  const updateCategories = (name) => {
    let newValues = [...values];
    let index = values.indexOf(name);
    if (index === -1) {
      newValues.push(name);
    } else {
      newValues.splice(index, 1)
    }
    change(newValues);
  }

  return (
    <div className='inputControl' key={values.join('-')}>
      {label && <label className='inputControl__label'>{label}</label>}
      <div className='categoryList'>
        {choices.map((choice) =>
          <button key={choice} disabled={disabled} className={`categoryPill ${values.indexOf(choice) !== -1 ? 'categoryPill-active' : ''}`} onClick={() => { updateCategories(choice) }}>{choice}</button>
        )}
      </div>

      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function MultiSelectObjects({ choices = [], change, values = [], label = '', help = '', disabled = false }) {

  const updateCategories = (choice) => {
    let newValues = [...values];
    let index = isInList(choice.value);
    if (index === -1) {
      newValues.push(choice);
    } else {
      newValues.splice(index, 1)
    }
    change(newValues.filter((choice) => { return choice !== null; }));
  }

  const isInList = (value) => {
    let isIt = -1;
    values.filter((choice) => { return choice !== null; }).forEach((val, index) => {
      if (val.value == value) {
        isIt = index;
      }
    })
    return isIt;
  }

  return (
    <div className='inputControl' key={values.join('-')}>
      {label && <label className='inputControl__label'>{label}</label>}
      <div className='categoryList'>
        {choices.filter((choice) => { return choice !== null; }).map((choice) =>
          <button key={choice.value} disabled={disabled} className={`categoryPill ${isInList(choice.value) >= 0 ? 'categoryPill-active' : ''}`} onClick={() => { updateCategories(choice) }}>{choice.label}</button>
        )}
      </div>

      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function NumberControl({
  value,
  change,
  label = '',
  help = '',
  placeholder = '',
  valid = true,
  name = '',
  classlist = '',
  disabled = false,
  min = 0,
  max = null,
  step = 1,
  important = '' }) {


  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      {important && <div className='form-important'>{important}</div>}
      <input
        id={name}
        disabled={disabled ? 'disabled' : ''}
        step={step}
        type='number'
        min={min}
        className={`${valid && 'valid'} ${classlist}`}
        max={max}
        placeholder={placeholder}
        value={value}
        onChange={(e) => { change(parseFloat(e.target.value)) }} />
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function DateControl({ value, change, label = '', help = '', required = false, disabled = false, classlist = '', valid = true, name = '' }) {
  const prepareDateForInput = (date) => {

    if (date) {
      try {
        return new Date(date).toISOString().split('T')[0];
      } catch (e) {
        return ''
      }
    } else {
      return '';
    }
  };

  const prepareDateTimeForInput = (date) => {
    if (date) {
      return new Date(date).toISOString();
    } else {
      return '';
    }
  }

  return (
    <div className='inputControl'>
      {label && <label for={name} className='inputControl__label'>{label} {required && <span className='required'>*</span>}</label>}
      <input
        id={name}
        disabled={disabled ? 'disabled' : ''}
        type={'date'}
        value={prepareDateForInput(value)}
        className={`${valid && 'valid'} ${classlist}`}
        onChange={(e) => { change(e.target.value) }} />
      {help && <div className='inputControl__help' dangerouslySetInnerHTML={{ __html: help }}></div>}
    </div>
  )
}

const useForceUpdate = () => useState()[1];

export function FileControl({ reference, image = false, label = '', help = '', valid = true, type = 'text', classlist = '', disabled = false, accept = '', multiple = false, name = '' }) {
  const forceUpdate = useForceUpdate();

  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      <input id={name} onChange={forceUpdate} disabled={disabled ? 'disabled' : ''} type='file' className={`${valid && 'valid'}`} accept={accept} ref={reference} multiple={multiple ? 'multiple' : false} />
      {help && <div className='inputControl__help'>Accepted file types: {accept}</div>}

      {image && reference.current && reference.current.files.length === 1 &&
        <img className='imagePreview' src={URL.createObjectURL(reference.current.files[0])} />
      }
      {image && reference.current && reference.current.files.length > 1 &&
        Array.from(reference.current.files).map((img) =>
          <img key={URL.createObjectURL(img)} className='imagePreview' src={URL.createObjectURL(img)} />
        )
      }
    </div>
  )
}


export function CountryControl({ value, change, label = '', help = '', valid = true, classlist = '', disabled = false }) {
  if (value == undefined || value == null) {
    change('');
  }
  const countries = require('./countries.json');
  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for='Country' className='inputControl__label'>{label}</label>}
      <select autoComplete='country' name='Country' disabled={disabled ? 'disabled' : ''} className={`${valid && 'valid'}`} value={value} onChange={(e) => { change(e.target.value) }}>
        <option value=''>--</option>
        {countries.map((opt) =>
          <option key={opt.value} value={opt.value}>{opt.label}</option>
        )}
      </select>
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function RangeControl({ value, change, label = '', help = '', max, min, step = 1, valid = true, symbol = '', classlist = '', disabled = false }) {
  const [localMin, setLocalMin] = useState(value[0] ? value[0] : min);
  const [localMax, setLocalMax] = useState(value[1] ? value[1] : max);
  const [range, setRange] = useState(max - min);
  const [dragging, setDragging] = useState('');
  const [tracking, setTracking] = useState('');

  const roundOffTo = (num, factor = 1) => {
    const quotient = num / factor;
    const res = Math.round(quotient) * factor;
    return res;
  };

  useEffect(() => {
    setRange(max - min);
  }, [min, max]);


  const trackingRef = useRef(tracking);
  const draggingRef = useRef(dragging);

  useEffect(() => {
    trackingRef.current = tracking;
    draggingRef.current = dragging;
  }, [dragging, tracking]);

  const slider = useRef();

  useEffect(() => {
    if (!tracking) {
      change([localMin, localMax]);
    }
  }, [tracking])

  const mouseTracker = (e) => {
    if (!trackingRef.current) {
      return;
    }
    let container = slider.current.getBoundingClientRect();
    let left = container.left;
    let right = container.right;
    let width = container.width;
    let mouse = e.clientX;
    if (draggingRef.current == 'min') {
      let offset = mouse - left;
      let perc = offset / width;
      perc = perc > 1 ? 1 : perc < 0 ? 0 : perc;
      let newValue = perc * (max - min);
      let rounded = roundOffTo(newValue, step);
      setLocalMin(rounded + min < localMax ? rounded + min : localMax);
    } else {
      let offset = mouse - left;
      let perc = offset / width;
      perc = perc > 1 ? 1 : perc < 0 ? 0 : perc;
      let newValue = perc * (max - min);
      let rounded = roundOffTo(newValue, step);
      setLocalMax(rounded + min < localMin ? localMin : rounded + min);
    }
  }

  useEffect(() => {
    document.addEventListener('mousemove', mouseTracker);
    document.addEventListener('mouseup', () => { setTracking(false) });
  }, []);

  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label className='inputControl__label'>{label}</label>}
      <div className='rangeSlider'>
        <div className='rangeSlider__track' ref={slider}>
          <div
            className='rangeSlider__min'
            onMouseDown={() => {
              setDragging('min');
              setTracking(true);
            }}
            style={{ left: `calc(${(localMin - min) / (range + 1) * 100}% - 8px)` }}
          ><div className='rangeSlider__label'>{symbol}{localMin}</div></div>
          <div
            className='rangeSlider__max'
            style={{ left: `calc(${(localMax - min) / range * 100}% - 8px)` }}
            onMouseDown={() => {
              setDragging('max');
              setTracking(true);
            }}
          ><div className='rangeSlider__label'>{symbol}{localMax}</div></div>
          {[...Array(Math.ceil(range / step))].map((item, index) =>
            <div key={index}
              className={`rangeSlider__stop ${(index + min + 1) * step >= localMin && (index + min + 1) * step <= localMax && 'selected'}`}
            ></div>
          )}
        </div>
      </div>
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}