import React from 'react';
import './styles.css';

export default function CatalogPage() {

  document.title = `Wholesale Catalog | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', `The latest wholesale catalog featuring new releases, bestsellers, publisher spotlights, and more from Asterism.`);


  return (
    <div>
      <h1 className='page-header'>Asterism Books Wholesale Catalog</h1>
      <div className='flex-layout flexColMobile'>
        <div className='flex-four pageContent'>
          <div className='asterismCard catalogCard'>
            <p><a href='https://asterismbooks.com/catalogs/LateSummerEarlyFall-2024.pdf'>Download and browse</a> or most recent catalog.</p>
            <div className='catalogCover'>
              <a href='https://asterismbooks.com/catalogs/LateSummerEarlyFall-2024.pdf'>
                <img src='https://asterismbooks.com/catalogs/LateSummerEarlyFall_2024_Cover.jpg' alt={'Asterism catalog cover'} />
              </a>
            </div>
          </div>
        </div>
        <div className='flex-two'>
          <h3>Want the print version?</h3>
          <p>Each quarter, we send out printed catalog to our bookselling and librarian colleagues. If you'd like to be added to our mailing list, please email info [@] asterismbooks.com</p>
        </div>
      </div>
    </div>
  )
}