import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { TextControl } from '../../components/InputControls';
import { checkAuth, makeRequest } from '../../Utils';
import { userState, messageState } from '../../atoms';

export default function Reset({ context }) {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [userData, setUserData] = useRecoilState(userState);
  const [message, setMessage] = useRecoilState(messageState);
  const [uploading, setUploading] = useState(false);

  document.title = `Set New Password | Asterism Books`;
  document.querySelector('meta[name="description"]').setAttribute('content', 'Reset your password');


  const navigate = useNavigate();

  const verifyToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get('resetToken');
    if (!resetToken) {
      setMessage({ type: 'error', label: 'problem', text: 'No valid reset token parameter', temp: true });
      return;
    }
    let data = await makeRequest(`verify-reset-token?resetToken=${resetToken}`);
    if (!data.email) {
      setMessage({ type: 'error', label: 'problem', text: 'No valid reset token parameter', temp: true });
    } else {
      setEmail(data.email);
    }
  }

  useEffect(() => {
    verifyToken();
  }, [])

  const submit = async () => {
    if (pass !== confirmPass) {
      setMessage({ type: 'error', label: 'problem', text: 'Passwords must match!', temp: true });
    } else if (pass.length < 6) {
      setMessage({ type: 'error', label: 'problem', text: 'Passwords must be at least six characters long', temp: true });
    }
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get('resetToken');
    setUploading(true);
    let data = {
      resetToken: resetToken,
      email: email,
      pass: pass,
      mode: context,
    };
    let request = await makeRequest('new-password', 'POST', data);
    if (request.ok) {
      setMessage({ type: 'success', label: 'success', text: 'Password successfully set! You can now log in.', temp: true });
      setTimeout(() => {
        if (context == 'publisher') {
          navigate('/pub/login');
        } else {
          navigate('/login');
        }
      }, 300)
    } else {
      setMessage({ type: 'error', label: 'problem', text: 'There was a problem', temp: true });
    }
    setUploading(false);
  }

  if (userData.id) {
    return (
      <div>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/account'}>your account</Link></h4>
      </div>
    )
  }

  return (
    <div className='card-container'>
      <div className='roomBelowMedium'>
        <h1 className='page-header'>Create a new password</h1>
      </div>

      <div className='loginForm'>
        <TextControl
          value={email}
          change={(value) => { setEmail(value) }}
          label={`Account Email`}
          valid={false}
          type='text'
          required={true}
          bypass={true}
          description={''}
          disabled={true}
        />
        <TextControl
          value={pass}
          change={(value) => { setPass(value) }}
          label={`New Password`}
          valid={false}
          type='password'
          bypass={true}
          autocomplete={'new-password'}
          required={true}
          description={''}
          disabled={!email || uploading}
        />
        <TextControl
          value={confirmPass}
          change={(value) => { setConfirmPass(value) }}
          label={`Confirm New Password`}
          valid={false}
          type='password'
          bypass={true}
          required={true}
          description={''}
          disabled={!email || uploading}

        />
      </div>

      <button className='buttonPrimary' onClick={submit}>Set New Password</button>
    </div>
  )
}
