import { TextControl, TextAreaControl, SelectControl } from '../../components/InputControls';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeRequest } from '../../Utils';
import { useRecoilState } from 'recoil';
import './style.css';
import { messageState } from '../../atoms';

export default function ContactForm({ headerText = 'Get in touch!', userName = '', userEmail = '' }) {
  const [name, setName] = useState(userName);
  const [order, setOrder] = useState('');
  const [reason, setReason] = useState(0);
  const [email, setEmail] = useState(userEmail);
  const [content, setContent] = useState('');
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useRecoilState(messageState);

  const sendMessage = async () => {
    if (!email || !name || !content) {
      setMessage({ type: 'error', label: 'Oops', temp: true, text: 'Please fill out all fields.' })
      return;
    }
    let options = {
      email: email,
      name: name,
      message: content,
      reason: reason,
      order: order,
      created: moment().toISOString(),
      modified: moment().toISOString(),
      status: 'new'
    }
    let data = await makeRequest('contact-form', 'POST', options);
    if (data.ok) {
      setSent(true);
    }
  }

  if (sent) {
    return (
      <div className='contactForm'>
        <h3 className='contactForm__header'>{headerText}</h3>
        <p>Thank you for your message! We'll get back to you shortly!</p>
      </div>
    )
  }

  return (
    <div className='contactForm'>
      <h3 className='contactForm__header'>{headerText}</h3>
      <TextControl
        value={name}
        change={(value) => {
          setName(value)
        }}
        label={`Your Name`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={email}
        change={(value) => {
          setEmail(value)
        }}
        label={`Your Email`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <SelectControl
        value={reason}
        change={(value) => {
          setReason(value)
        }}
        label={'Reason for getting in touch'}
        options={[
          {
            label: 'General Question',
            value: 0
          },
          {
            label: 'Request for tracking or shipping update',
            value: 1
          },
          {
            label: 'Missing/wrong book in package',
            value: 2
          },
          {
            label: 'Edit or cancel an order',
            value: 3
          },
          {
            label: 'Request information on a product',
            value: 4
          }
        ]}
      />

      <TextControl
        value={name}
        change={(value) => {
          setName(value)
        }}
        label={`Receipt or Order Number`}
        type="text"
        bypass={false}
        help={"#000-000 or AST-01-0001"}
      />



      <TextAreaControl
        value={content}
        change={(value) => {
          setContent(value)
        }}
        label={`Message`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
        rows={6}
      />

      <button className='buttonPrimary' onClick={sendMessage}>Submit</button>
    </div>
  )
}