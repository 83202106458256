export const ticketTypes = {
  'gi': 'General Info',
  'ti': 'Tracking Request',
  'pq': 'Publisher Question',
  'lp': 'Lost Package',
  'wi': 'Wrong Items in Shipment',
  'bo': 'Backorder status update',
  'po': 'Pre-order status update',
  'tl': 'Package taking too long',
  'ws': 'Package sent with incorrect service',
  'db': 'Damaged books',
  'pl': 'Package lost',
  'rr': 'Refund Request',
  'er': 'Event Returns'
}

export const ticketResolutionTypes = {
  'ri': 'Refund Issued',
  'pr': 'Partial Refund',
  'sh': 'Shipping Refund',
  'st': 'Tracking info sent',
  'ep': 'Explained pre-order',
  'as': 'Asendia info sent',
  'bu': 'Backorder update',
  'sr': 'Sent receipt',
  'si': 'Sent invoice',
  'se': 'Sent eBook download link',
  'ot': 'Other'
}